import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Page from 'components/Page';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Link as RouterLink } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { superAdmin, API_URL } from 'config/appConfig';
import { fDateTime, fDate } from 'utils/formatTime';
import { PATH_APP } from 'config/paths';
import { IconButton } from '@mui/material';
import useManageUser from 'hooks/useManageUser';
import useAuth from 'hooks/useAuth';
import ActionBtnUser from './ActionBtnUser';
import EditBtnUser from './EditBtnUser';
import ChangePassBtnUser from './ChangePassBtnUser';
// ----------------------------------------------------------------------
export default function UserGrid() {
  const { user } = useAuth();
  const { manageUsers, initManageUser } = useManageUser();
  useEffect(() => {
    initManageUser();
  }, []);

  if (!user) return <></>;

  return (
    <Page
      title="Liste des utilisateurs | OCS - Orthodontie | Céphalométrie | Superposition"
      style={{ height: '100%' }}
    >
      <Container maxWidth="xl" style={{ height: '100%' }}>
        {user.role !== superAdmin ? (
          <Box sx={{ pb: 5 }} display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="h4">L'accès est réservé au Super Admin</Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={{ pb: 5 }} display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Typography variant="h4">Liste des accès d'utilisateur</Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end" sx={{ my: 2 }}>
                <EditBtnUser />
              </Box>
            </Box>
            {manageUsers && <Grid result={manageUsers} />}
          </>
        )}
      </Container>
    </Page>
  );
}
const Grid = ({ result, ...props }) => {
  const { initManageUser, delUser, changeLock } = useManageUser();
  if (!result.results) return <></>;
  const handleDelete = async (u) => {
    try {
      await delUser(u.id);
      await initManageUser();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const handleChangeLockUser = async (u) => {
    try {
      await changeLock(u.id);
      await initManageUser();
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const rows = result.results;
  const columns = [
    {
      field: 'name',
      headerName: 'Nom',
      sortable: true,
      width: 160,
      renderCell: (params) => {
        const { row } = params;
        if (!row.isEnabled) return <Box sx={{ color: 'red' }}>{row.name} </Box>;
        return row.name;
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250
    },
    {
      field: 'createdAt',
      headerName: 'Créé le',
      width: 150,
      valueGetter: (params) => {
        const { row } = params;
        if (!row.createdAt) return '';
        try {
          return `${fDateTime(row.createdAt)}`;
        } catch (error) {
          return '';
        }
      }
    },
    {
      field: 'updatedAt',
      headerName: 'Mise à jour le',
      width: 150,
      valueGetter: (params) => {
        const { row } = params;
        if (!row.updatedAt) return '';
        try {
          return `${fDateTime(row.updatedAt)}`;
        } catch (error) {
          return '';
        }
      }
    },
    {
      field: 'expiredAt',
      headerName: `Date d'expiration`,
      width: 150,
      valueGetter: (params) => {
        const { row } = params;
        if (!row.expiredAt) return '';
        try {
          return `${fDate(row.expiredAt)}`;
        } catch (error) {
          return '';
        }
      }
    },
    {
      field: 'action',
      headerName: 'Actions',
      width: 250,
      renderCell: (params) => {
        try {
          const { id, row } = params;
          return (
            <Box display="flex" alignItems="center">
              <EditBtnUser editUser={row} />
              {!row.isEnabled ? (
                <ActionBtnUser
                  title={`Réactivation d'utilisateur ${row.name}`}
                  msg="L'accès à l'application sera réactivé. Confirmez-vous ?"
                  notifMsg="Réactivation avec succès."
                  icon={<LockOpenIcon />}
                  callback={() => handleChangeLockUser(row)}
                  color="primary"
                />
              ) : (
                <ActionBtnUser
                  title={`Verrouillage d'accès à l'utilisateur ${row.name}`}
                  msg="L'accès à l'application sera verrouillé. Confirmez-vous ?"
                  notifMsg="Verrouillage avec succès."
                  icon={<LockIcon />}
                  color="warning"
                  callback={() => handleChangeLockUser(row)}
                />
              )}

              {row.id && <ChangePassBtnUser editUser={row} />}
            </Box>
          );
        } catch (error) {
          return <></>;
        }
      }
    }
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[50]}
        checkboxSelection
        disableSelectionOnClick
      />
    </>
  );
};
