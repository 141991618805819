import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify-icons/eva/close-fill';
import { MIconButton } from 'theme/@material-extend/Buttons';
import EditIcon from '@mui/icons-material/Edit';

// material
import {
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  Dialog,
  Stack,
  TextField,
  MenuItem,
  IconButton
} from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { LoadingButton } from '@mui/lab';
import 'dayjs/locale/fr';
import dayjs, { Dayjs } from 'dayjs';
import usePatient from 'hooks/usePatient';
import { PATH_APP } from 'config/paths';
import useManageUser from 'hooks/useManageUser';
// ----------------------------------------------------------------------
const ADMIN = ['admin'];
const LOCALE_DEF = 'fr';
export default function EditBtnUser({ editUser, ...props }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isEditMode = editUser && editUser.id ? true : false;

  return (
    <>
      {isEditMode ? (
        <IconButton size="small" variant="outline" color="primary" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      ) : (
        <Button onClick={handleClickOpen} variant="contained" color="primary" endIcon={<AddIcon />}>
          Ajouter
        </Button>
      )}

      <Dialog
        open={open}
        backdropClick={() => console.log()}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>{} </DialogTitle>
        <DialogContent>
          <FormUser editUser={editUser} onComplete={handleClose} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Annuler</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
// ----------------------------------------------------------------------
function FormUser({ editUser, onComplete, ...prorps }) {
  const isEditMode = editUser && editUser.id ? true : false;

  const { initManageUser, saveUser } = useManageUser();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const formSchema = Yup.object().shape({
    name: Yup.string().required('Veuillez renseigner votre nom et prénom'),
    email: Yup.string().email(`Email n'est pas valide`),
    password: Yup.string(),
    role: Yup.string()
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: editUser && editUser.name ? editUser.name : '',
      email: editUser && editUser.email ? editUser.email : '',
      password: editUser && editUser.password ? editUser.password : '',
      role: editUser && editUser.role ? editUser.role : '',
      expiredAt: editUser && editUser.expiredAt ? editUser.expiredAt : ''
    },
    validationSchema: formSchema,
    onSubmit: async () => {
      try {
        const newUserValues = { ...values, id: editUser && editUser.id ? editUser.id : null };
        const data = await saveUser(newUserValues);
        await initManageUser();
        onComplete();
        enqueueSnackbar('Sauvegarde réussie', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      } catch (error) {
        console.error(error);
        let msg = '';
        if (error.response) {
          msg = error.response.data.message;
        } else {
          msg = 'Network error';
        }
        enqueueSnackbar(msg, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setFieldValue,
    handleSubmit,
    getFieldProps,
    handleChange
  } = formik;
  const disabledField = false;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={LOCALE_DEF}>
          <Stack spacing={3} sx={{ mt: 5 }}>
            <TextField
              disabled={disabledField}
              fullWidth
              type="text"
              label="Nom"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              disabled={disabledField}
              fullWidth
              type="email"
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            {!isEditMode && (
              <TextField
                disabled={disabledField}
                fullWidth
                type="text"
                label="Mot de passe"
                {...getFieldProps('password')}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
            )}
            <TextField
              disabled={disabledField}
              select
              label="Profil"
              value={values.role}
              onChange={handleChange('role')}
              {...getFieldProps('role')}
              error={Boolean(touched.role && errors.role)}
              helperText={touched.role && errors.role}
            >
              {ADMIN.map((option, i) => (
                <MenuItem key={i} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <DesktopDatePicker
              disabled={disabledField}
              error={Boolean(touched.expiredAt && errors.expiredAt)}
              helperText={touched.expiredAt && errors.expiredAt}
              label="Date d'expiration"
              onChange={(value) => setFieldValue('expiredAt', value, true)}
              minDate={dayjs()}
              value={values.expiredAt}
              InputProps={{ disableUnderline: false }}
              renderInput={(params) => (
                <TextField
                  margin="normal"
                  name="expiredAt"
                  variant="standard"
                  fullWidth
                  {...params}
                />
              )}
            />

            <LoadingButton
              disabled={disabledField}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Valider
            </LoadingButton>
          </Stack>
        </LocalizationProvider>
      </Form>
    </FormikProvider>
  );
}
