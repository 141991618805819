import React, { useEffect, useState } from 'react';
import { API_URL } from 'config/appConfig';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import CheckIcon from '@mui/icons-material/Check';
import {
  Button,
  IconButton,
  Card,
  Popover,
  CardActions,
  CardContent,
  Typography
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import axios from 'axios';
import usePatient from 'hooks/usePatient';
import usePointTracing from 'hooks/usePointTracing';
import useNotification from 'hooks/useNotification';
// ----------------------------------------------------------------------
export default function LoadTracingBtn({ editTracingStep, editPoint, onComplete }) {
  const { notif } = useNotification();
  const { patient } = usePatient();
  const [tracingList, settracingList] = useState(null);
  const [submitting, setsubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { createTracingFromModel, getTracingStepByPointTraincing } = usePointTracing();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const init = async () => {
      try {
        const res = await axios.get(
          `${API_URL.tracingStep}/load-all-pointtracing${
            editTracingStep ? '/' + editTracingStep.id : ''
          } `
        );
        if (res && res.data) {
          settracingList(res.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [editTracingStep]);
  const onSelectPointStep = async (it) => {
    try {
      const editPointStepId = editPoint.id;
      const patientTracingStepId = editTracingStep ? editTracingStep.id : 0;
      const modelTracingStepId = it.id;
      await createTracingFromModel(editPointStepId, patientTracingStepId, modelTracingStepId);
      await getTracingStepByPointTraincing(editPointStepId);
      onComplete();
      notif('Téléchargement réussie');
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  if (!tracingList || tracingList.length === 0) return <></>;
  return (
    <div>
      <Button
        variant="contained"
        aria-label="details"
        size="small"
        onClick={handleClick}
        color="error"
        sx={{ alignItems: 'center' }}
      >
        <CloudDownloadIcon sx={{ mr: 1 }} /> Importer les tracés
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Card
          sx={{
            minWidth: 275
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
              Sélectionner un modèle de tracé dans la liste
            </Typography>
            {tracingList && (
              <TracingList
                list={tracingList}
                patient={patient}
                onSelectPointStep={onSelectPointStep}
              />
            )}
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
}
const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper
}));
const TracingList = ({ list, patient, onSelectPointStep, ...props }) => {
  const [onlyPatient, setonlyPatient] = useState(false);

  const getLabel = () => {
    try {
      if (!patient) return '';
      const { firstname, lastname } = patient;
      return `Afficher uniquement les tracés du patient ${firstname} ${lastname}`;
    } catch (error) {
      console.error(error);
    }
    return '';
  };

  const getInfos = (it) => {
    const infos = { display: false, primaryText: null, secondaryText: null, avatar: null };
    try {
      const { pointtracing } = it;
      if (!pointtracing) return infos;
      const { refstep } = pointtracing;
      if (!refstep || !pointtracing.patient) return infos;
      infos.display = onlyPatient ? String(pointtracing.patient.id) === String(patient.id) : true;
      infos.avatar = pointtracing.patient.firstname.split('')[0].toUpperCase();
      infos.primaryText = `${pointtracing.patient.firstname} ${pointtracing.patient.lastname}`;
      infos.secondaryText = `${refstep.label}`;
    } catch (error) {
      console.error(error);
      infos.display = false;
    }
    return infos;
  };
  return (
    <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyPatient}
              onChange={(event) => setonlyPatient(event.target.checked)}
            />
          }
          color="primary"
          label={getLabel()}
        />
      </FormGroup>
      <Grid item xs={12} md={6}>
        <Demo>
          <List dense>
            {list.map((it, i) => {
              const infos = getInfos(it);
              return (
                <React.Fragment key={i}>
                  {infos.display && (
                    <ListItem
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="Select"
                          onClick={() => onSelectPointStep(it)}
                        >
                          <CheckIcon />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar>{infos.avatar}</Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={infos.primaryText} secondary={infos.secondaryText} />
                    </ListItem>
                  )}
                </React.Fragment>
              );
            })}
          </List>
        </Demo>
      </Grid>
    </Box>
  );
};
