import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import { PATH_APP } from 'config/paths';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Utilisateurs',
    path: PATH_APP.userManage,
    icon: getIcon(bookOpenFill)
  },
  {
    title: 'Patients',
    path: PATH_APP.patients,
    icon: getIcon(peopleFill)
  },
  {
    title: 'Paramètres',
    path: PATH_APP.configuration,
    icon: getIcon(lockFill),
    children: [
      {
        title: 'Radiologies',
        path: PATH_APP.radiosSettings
      },
      {
        title: 'Points et positions',
        path: PATH_APP.pointsSettings
      },
      {
        title: 'Plans',
        path: PATH_APP.linesSettings
      },
      {
        title: 'Angles',
        path: PATH_APP.anglesSettings
      },
      {
        title: 'Distances',
        path: PATH_APP.distancesSettings
      },
      {
        title: 'Tracés',
        path: PATH_APP.tracingsSettings
      },
      /*
      {
        title: 'Superposition',
        path: PATH_APP.superSettings
      },*/
      {
        title: 'Rapport',
        path: PATH_APP.reportSettings
      }
    ]
  }
];

export default sidebarConfig;
