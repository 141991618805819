import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PolylineIcon from '@mui/icons-material/Polyline';
import { styled, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import DoneIcon from '@mui/icons-material/Done';
import NearMeIcon from '@mui/icons-material/NearMe';
import { MIconButton } from 'theme/@material-extend/Buttons';
import {
  SVGPathData,
  SVGPathDataTransformer,
  SVGPathDataEncoder,
  SVGPathDataParser
} from 'svg-pathdata';
import * as d3 from 'd3';
import { zoom } from 'd3-zoom';
import { drag } from 'd3-drag';
import { path } from 'd3-path';
import {
  updatePath,
  setMovePoint,
  getBoundingBoxCenter,
  getMovePoint,
  drawLineMaxi,
  parseToCommands,
  toRel,
  applyTranformToGroupToPath,
  getControlPointFromTracing,
  getPerpendLineVerticalPath,
  getPointsFromPath,
  getTransformPath,
  updatePathString
} from 'utils/pathUtil';
import {
  SVGEDITOR_WIDTH,
  SVGEDITOR_HEIGHT,
  SVGEDITOR_POINT_RADIUS,
  SVGEDITOR_LABEL_SPACING,
  SVGEDITOR_TOOLBAR_WIDTH,
  API_URL,
  SUPER_GENERALE,
  SUPER_MAXI,
  SUPER_MANDI,
  SUPER_COLOR__FILL,
  IMPLAN_LINE,
  IMPLAN_LINE_IDPATH,
  TRANCING_GROUP_ID,
  SVGEDITOR_TRACING_STROKEWIDTH,
  IMPLANLINE_MAXI_DIMENSIONS,
  TRACING_TYPE_MAXI,
  TRACING_TYPE_MANDI,
  TRANSFERT_GUIDE_IDPATH
} from 'config/appConfig';
import useReferentiel from 'hooks/useReferentiel';
import { blobToBase64 } from 'utils/imageUtil';
import usePointTracing from 'hooks/usePointTracing';
import useNotification from 'hooks/useNotification';
import {
  distanceBetweenPoints,
  toSvgData,
  xmlToBase64,
  getTransform,
  setTransform,
  ROTATE,
  TRANSLATE,
  SCALE,
  distance,
  calculateAngles,
  distancePointToLine,
  hexToRGB
} from 'utils/d3Util';
import usePatient from 'hooks/usePatient';
import SvgEditorCloseBtn from 'components/d3/SvgEditorCloseBtn';
import useEditPath from 'hooks/useEditPath';
import SvgToImgDownload from 'components/media/SvgToImgDownload';

import TransformSuper from './TransformSuper';
// ----------------------------------------------------------------------
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));
const TRANSITION_TIME = 750;
const TRACING_GROUP = {
  globalGroup: TRANCING_GROUP_ID,
  idGroup: null,
  currentNoTransform: null
};
// ----------------------------------------------------------------------
const specificsId = [IMPLAN_LINE_IDPATH, TRANSFERT_GUIDE_IDPATH];
export default function DialogSuperLocalEditor({
  editSuperposition,
  editTracingsSuperpo,
  editTracings,
  onComplete,
  handleClose
}) {
  const {
    mixins: { toolbar }
  } = useTheme();
  const { refs } = useReferentiel();
  const { notif } = useNotification();
  /* svg editor */
  const svgRef = useRef(null);
  const zoomHandler = zoom();
  const handler = drag();
  const timer = useRef(null);
  const { patient } = usePatient();
  const disabledField = patient && patient.id && patient.lockOff;
  const [implanLine, setimplanLine] = useState(null);
  const [currentTracings, setcurrentTracings] = useState(null);

  //--------------------------------------------------------------------
  const boxTransformGroupRef = useRef(null);
  const editTracingGroupRef = useRef(TRACING_GROUP);
  const savedTransformRef = useRef([]);
  // ----------------------------------------------------------------------
  const {
    initEditSvgGroup,
    handleUpdateSvgGroup,
    getEditGroupRef,
    restoreToStep,
    redrawUsesForGroup
  } = useEditPath();
  // ----------------------------------------------------------------------
  /**listener keydown for edit path */
  useEffect(() => {
    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, []);

  // ----------------------------------------------------------------------
  const KEYDOWN = [90, 89];
  const keydownHandler = (e) => {
    if (KEYDOWN.includes(e.keyCode) && e.ctrlKey) {
      const { histoGroup, createdAt } = getEditGroupRef();
      const isCtrlZ = e.keyCode === 90;
      const isHistoNotEmpty = histoGroup.length > 0;
      if (!isHistoNotEmpty) return;
      const idxCurrentState = histoGroup.findIndex((it) => {
        return it.createdAt.getTime() === createdAt.getTime();
      });
      const idx = isCtrlZ ? idxCurrentState - 1 : idxCurrentState + 1;
      if (idx < 0 || idx > histoGroup.length - 1) return;

      const goToHisto = histoGroup[idx];
      restoreToStep(goToHisto);
    }
  };
  //--------------------------------------------------------------------
  useEffect(() => {
    if (!editSuperposition) return;
    if (!editSuperposition.tracings) return;
    setcurrentTracings([...editSuperposition.tracings]);
    if (editSuperposition.implanLine) {
      setimplanLine({
        ...editSuperposition.implanLine,
        updatedAt: new Date()
      });
    } else {
      setimplanLine({ path: null, updatedAt: new Date() });
    }
  }, [editSuperposition]);
  /** construct tracing on svg */
  useEffect(() => {
    if (!currentTracings) return;
    if (!implanLine) return;
    if (!implanLine.updatedAt) return;
    //reset
    boxTransformGroupRef.current.style.display = 'none';
    const gGlobal = d3.select(`#${TRANCING_GROUP_ID}`);
    currentTracings.forEach((trac) => {
      const { color, id, tracings } = trac;
      const tracingGroupId = 'tracingGroup_' + id;
      const toBeDeleted = d3.select(`#${tracingGroupId}`);
      if (!toBeDeleted.empty()) {
        toBeDeleted.remove();
      }
      const g = gGlobal
        .append('g')
        .attr('id', tracingGroupId)
        .attr('idGroup', tracingGroupId)
        .attr('idPointTracing', id)
        .attr('groupColor', color);

      tracings.forEach((it) => {
        const idPath = getSpecificId(it.id) ? it.id : `id_${trac.id}_${it.id}`;
        g.append('path')
          .attr('id', idPath)
          .attr('code', it.code)
          .attr('idPath', idPath)
          .attr('idGroup', tracingGroupId)
          .attr('d', it.imgpath)
          .attr('fill', 'transparent')
          .attr('stroke', color ? color : 'black')
          .attr('stroke-width', SVGEDITOR_TRACING_STROKEWIDTH)
          .attr('opacity', it.display ? '1' : 0);
      });
      if (!implanLine.path) {
        g.on('mouseover', function (d) {
          d3.select(this).style('cursor', 'move').attr('fill', 'black');
        })
          .on('mouseout', function (d) {})
          .on('click', (e) => {
            handleClickOnPath(tracingGroupId);
          })
          .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended))
          .raise();
      }
    });
    if (implanLine.path) {
      /** add impline */
      const g = gGlobal.append('g').attr('id', IMPLAN_LINE).attr('idGroup', IMPLAN_LINE);

      g.append('path')
        .attr('id', IMPLAN_LINE_IDPATH)
        .attr('idGroup', IMPLAN_LINE)
        .attr('d', implanLine.path)
        .attr('fill', 'transparent')
        .attr('stroke', 'black')
        .attr('stroke-width', SVGEDITOR_TRACING_STROKEWIDTH);
      if (!implanLine.lockChange) {
        if (canMoving) {
          const dImplanLine = {
            imgpath: implanLine.path,
            color: 'black',
            id: IMPLAN_LINE_IDPATH
          };
          const circles = getControlPointFromTracing(dImplanLine);
          if (circles) {
            drawControlGroup(g, circles);
          }
        }

        const pointMove = getMovePoint(d3.select(`#${IMPLAN_LINE_IDPATH}`).attr('d'));
        const { x, y } = pointMove;
        const idUse = `use_${IMPLAN_LINE}`;
        g.append('use')
          .attr('id', idUse)
          .attr('idGroup', IMPLAN_LINE)
          .attr('idPath', IMPLAN_LINE_IDPATH)
          .attr('color', 'black')
          .attr('href', '#pointer')
          .attr('x', x)
          .attr('y', y)
          .attr('fill', 'black')
          .attr('stroke', 'black')
          .on('mouseover', function (d) {
            d3.select(this).style('cursor', 'move').attr('fill', 'black');
          })
          .on('mouseout', function (d) {})
          .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended))
          .raise();
      }
    }
    /** initialize state of svg */
    initEditSvgGroup(TRANCING_GROUP_ID);
  }, [currentTracings, implanLine]);
  // ----------------------------------------------------------------------
  const drawControlGroup = (g, circles) => {
    g.selectAll('circle')
      .data(circles, ({ uid }) => uid)
      .join(
        (enter) => {
          return bindingToCircle(g, enter);
        },
        (update) => {
          return update;
        },
        (exit) => {
          return exit
            .transition()
            .duration(300)
            .style('opacity', 0)
            .on('end', function () {
              d3.select(this).remove();
            });
        }
      );
  };
  const bindingToCircle = (g, item) => {
    return item
      .append('circle')
      .attr('id', (d) => d.id)
      .attr('uid', (d) => d.uid)
      .attr('idPath', (d) => d.idPath)
      .attr('idGroup', (d) => g.attr('id'))
      .attr('label', (d) => d.label)
      .attr('cx', (d) => d.cx)
      .attr('cy', (d) => d.cy)
      .attr('r', Number(SVGEDITOR_POINT_RADIUS) + 2)
      .attr('fill', 'transparent')
      .attr('stroke', (d) => (d.isOnPath ? d.color : 'blue'))
      .on('mouseover', function (d) {
        d3.select(this).style('cursor', 'move');
      })
      .on('mouseout', function (d) {})
      .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended))
      .raise();
  };
  // ----------------------------------------------------------------------
  const handleClickOnPath = (tracingGroupId) => {
    setForAllGroup('path', [{ key: 'fill', value: 'transparent' }]);

    boxTransformGroupRef.current.style.display = 'none';
    const isGroupEditing = d3.select(`#${tracingGroupId}`).attr('editing') === 'true';

    if (!isGroupEditing) {
      const groupColor = d3.select(`#${tracingGroupId}`).attr('groupColor')
        ? hexToRGB(d3.select(`#${tracingGroupId}`).attr('groupColor'), 0.2)
        : SUPER_COLOR__FILL;
      d3.select(`#${tracingGroupId}`)
        .attr('editing', true)
        .raise()
        .selectAll('path')
        .attr('fill', groupColor);
      boxTransformGroupRef.current.style.display = 'block';
      editTracingGroupRef.current = {
        globalGroup: TRANCING_GROUP_ID,
        idGroup: tracingGroupId,
        currentNoTransform: {
          centerPoint: getBoundingBoxCenter(d3.select(`#${tracingGroupId}`)),
          nodes: d3
            .select(`#${tracingGroupId}`)
            .selectAll('path')
            .nodes()
            .map((it) => {
              const pathGroup = d3.select(it);
              return { id: pathGroup.attr('id'), imgpath: pathGroup.attr('d') };
            })
        }
      };
    } else {
      d3.select(`#${tracingGroupId}`).attr('editing', null);
      editTracingGroupRef.current = {
        globalGroup: TRANCING_GROUP_ID,
        idGroup: tracingGroupId,
        currentNoTransform: {
          centerPoint: getBoundingBoxCenter(d3.select(`#${tracingGroupId}`))
        }
      };
    }
  };
  const setForAllGroup = (type, values) => {
    currentTracings.forEach((it) => {
      const { id } = it;
      const idGroup = `tracingGroup_${id}`;
      const group = d3.select(`#${idGroup}`);
      if (!group.empty()) {
        values.forEach((item) => {
          const { key, value } = item;
          if (type === 'path') {
            group.selectAll('path').attr(key, value);
          } else if (type === 'group') {
            group.attr(key, value);
          }
        });
      }
    });
  };
  // ----------------------------------------------------------------------
  /** drag functions */
  const dragstarted = (event, d) => {
    const { sourceEvent } = event;
    if (!sourceEvent) return;
    const { target } = sourceEvent;
    const movePoint = d3.select(target);
    const idGroup = movePoint.attr('idGroup');
    if (!idGroup) return;
  };
  const dragged = (event, d) => {
    const { x, y, dx, dy } = event;
    const { sourceEvent } = event;
    if (!sourceEvent) return;
    const { target } = sourceEvent;
    let movePoint = d3.select(target);
    if (!movePoint) return;
    if (d) {
      /* move controls points */

      if (editSuperposition.code === TRACING_TYPE_MAXI) {
        /** in maxillaire, there are 2 points to moving free id_2 and id_3
         * point id_0, id_1 only perpendicule
         *
         */
        const fromTo = ['id_0', 'id_1'];
        if (fromTo.includes(d.id)) {
          const start = [
            Number(d3.select(`#id_0`).attr('cx')),
            Number(d3.select(`#id_0`).attr('cy'))
          ];
          const end = [
            Number(d3.select(`#id_1`).attr('cx')),
            Number(d3.select(`#id_1`).attr('cy'))
          ];

          const length = Math.sqrt(Math.pow(start[0] - end[0], 2) + Math.pow(start[1] - end[1], 2));

          const dirUnitVector = [(end[0] - start[0]) / length, (end[1] - start[1]) / length];
          //
          const mouseVector = [x - start[0], y - start[1]];
          const projection = mouseVector[0] * dirUnitVector[0] + mouseVector[1] * dirUnitVector[1];
          const moveCx = start[0] + dirUnitVector[0] * projection;
          const moveCy = start[1] + dirUnitVector[1] * projection;
          const { id, idPath } = d;
          d3.select(`#${id}`).attr('cx', moveCx).attr('cy', moveCy);
          updatePath(idPath, { ...d, x: moveCx, y: moveCy });
        } else {
          const { id, idPath } = d;
          const distanceCalculate = distance(
            {
              x: Number(d3.select(`#id_0`).attr('cx')),
              y: Number(d3.select(`#id_0`).attr('cy'))
            },
            {
              x: Number(d3.select(`#id_1`).attr('cx')),
              y: Number(d3.select(`#id_1`).attr('cy'))
            }
          );
          d3.select(`#${id}`)
            .attr('cx', (d.x = event.x))
            .attr('cy', (d.y = event.y));
          const from = {
            x: Number(d3.select(`#id_2`).attr('cx')),
            y: Number(d3.select(`#id_2`).attr('cy'))
          };
          const to = {
            x: Number(d3.select(`#id_3`).attr('cx')),
            y: Number(d3.select(`#id_3`).attr('cy'))
          };
          const newPath = getPerpendLineVerticalPath(from, to, distanceCalculate / 2);
          d3.select(`#${idPath}`).attr('d', newPath);
          const dImplanLine = {
            imgpath: newPath,
            color: 'black',
            id: idPath
          };
          const circles = getControlPointFromTracing(dImplanLine);
          if (circles) {
            drawControlGroup(d3.select(`#${IMPLAN_LINE}`), circles);
          }
        }
        //reset move use
        const pointMove = getMovePoint(d3.select(`#${d.idPath}`).attr('d'));
        if (pointMove) {
          const { x, y } = pointMove;
          const idUse = `use_${IMPLAN_LINE}`;
          d3.select(`#${idUse}`).attr('x', x).attr('y', y);
        }
      } else {
        const { id, idPath } = d;
        d3.select(`#${id}`)
          .attr('cx', (d.x = event.x))
          .attr('cy', (d.y = event.y));
        updatePath(idPath, { ...d, x: event.x, y: event.y });
      }
    } else {
      const isGroupTransfert = movePoint && movePoint.attr('idGroup') === IMPLAN_LINE;
      const isTransfertInSvg = d3.select(`#${IMPLAN_LINE_IDPATH}`);
      const isTransfertGuide = isGroupTransfert || (isTransfertInSvg && !isTransfertInSvg.empty());

      if (isTransfertGuide) {
        const idPath = IMPLAN_LINE_IDPATH;

        if (!idPath) return;
        movePoint = d3.select(`#use_${IMPLAN_LINE}`);
        const targetX = Number(movePoint.attr('x')) + Number(dx);
        const targetY = Number(movePoint.attr('y')) + Number(dy);
        movePoint.attr('x', targetX).attr('y', targetY);
        setMovePoint(idPath, { x: targetX, y: targetY });

        return;
      }
      const idGroup = getGroupEditing(movePoint);
      if (!idGroup) return;
      const translateValue = { x: Number(dx), y: Number(dy) };
      d3.select(`#${idGroup}`)
        .selectAll('path')
        .nodes()
        .forEach((node) => {
          const nodePath = d3.select(node);
          const currentPath = nodePath.attr('d');
          /** imgPath, scale, rotate, centerPoint, translate */
          if (currentPath) {
            const encodePath = applyTranformToGroupToPath(currentPath, null, translateValue);
            nodePath.attr('d', encodePath);
          }
        });
    }
  };
  const dragended = (event, d) => {
    const { sourceEvent } = event;
    if (!sourceEvent) return;
    const { target } = sourceEvent;
    const movePoint = d3.select(target);
    if (!movePoint) return;

    if (d) {
      console.log();
    } else {
      const { tagName } = movePoint.node();
      if (tagName === 'use') {
        const path = d3.select(`#${IMPLAN_LINE_IDPATH}`);
        if (path && !path.empty()) {
          const dImplanLine = {
            imgpath: path.attr('d'),
            color: 'black',
            id: IMPLAN_LINE_IDPATH
          };
          const circles = getControlPointFromTracing(dImplanLine);
          if (circles) {
            const g = d3.select(`#${IMPLAN_LINE}`);
            drawControlGroup(g, circles);
          }
        }
      } else {
        const idGroup = getGroupEditing(movePoint);
        if (!idGroup) return;
        editTracingGroupRef.current = {
          globalGroup: TRANCING_GROUP_ID,
          idGroup,
          currentNoTransform: {
            centerPoint: getBoundingBoxCenter(d3.select(`#${idGroup}`)),
            nodes: d3
              .select(`#${idGroup}`)
              .selectAll('path')
              .nodes()
              .map((it) => {
                const pathGroup = d3.select(it);
                return { id: pathGroup.attr('id'), imgpath: pathGroup.attr('d') };
              })
          }
        };
        timer.current = setTimeout(() => {
          const isGroupEditingAgain = d3.select(`#${idGroup}`).attr('editing') === 'true';
          if (isGroupEditingAgain) {
            handleUpdateSvgGroup(TRANCING_GROUP_ID);
          }
        }, 500);
        updateCenterPoint(idGroup);
      }
    }
    // constructTransformTransfert(currentTracings);
  };

  const getGroupEditing = (movePoint) => {
    const idGroup = movePoint.attr('idGroup');
    const g = d3.select(`#${idGroup}`);
    const isCurrentEditing = !g.empty() && g.attr('editing') === 'true' ? true : false;
    if (isCurrentEditing) {
      return idGroup;
    }

    for (let i = 0; i < currentTracings.length; i++) {
      const it = currentTracings[i];
      const { id } = it;
      const idGroup = `tracingGroup_${id}`;
      const g = d3.select(`#${idGroup}`);
      if (g && !g.empty() && g.attr('editing') === 'true') {
        return idGroup;
      }
    }

    return null;
  };
  const canMoving = () => {
    try {
      if (!editSuperposition) return false;
      if (editSuperposition.code === 2) {
        //maxillaire
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
    return false;
  };
  //--------------------------------------------------------------------
  const updateCenterPoint = (idGroup) => {
    const arr = [...savedTransformRef.current];
    const idx = arr.findIndex((it) => it.idGroup === idGroup);
    if (idx < 0) {
      return;
    }
    const groupAngle = arr[idx];
    const centerPoint = getBoundingBoxCenter(d3.select(`#${idGroup}`));
    groupAngle.centerPoint = centerPoint;
    arr[idx] = groupAngle;
    savedTransformRef.current = arr;
  };
  /** when rotate popover closed, update values */
  const hancleCloseSuperTransform = (groupAngle) => {
    if (!currentTracings) return;
    if (!groupAngle) return;
    const { idGroup } = groupAngle;
    const arr = [...savedTransformRef.current];
    const idx = arr.findIndex((it) => it.idGroup === idGroup);
    const superGeneral = editTracingsSuperpo.find((it) => it.code === SUPER_GENERALE);
    if (!superGeneral) return;
    const refTransfertLineY = [0, 0, 0, 100];
    const refTransfertLineX = [0, 0, 100, 0];
    const groupSuperGenId = idGroup.split('_').pop();
    const t = currentTracings.find((it) => it.id === groupSuperGenId);
    /** */
    const { transfertGuide } = t;
    const transfert = d3.select(`#${transfertGuide.id}`);
    const dImplanLine = {
      imgpath: transfert.attr('d'),
      color: 'black',
      id: transfertGuide.id
    };
    const points = getControlPointFromTracing(dImplanLine);
    const transfertLine = [points[0].cx, points[0].cy, points[1].cx, points[1].cy];
    //get transfert guide referentiel by refstep code (debut, eval, content, fin)
    const angleX = calculateAngles(refTransfertLineX, transfertLine, false); //isSuperior =false
    const angleY = calculateAngles(refTransfertLineY, transfertLine, false); //isSuperior =false
    const centerPoint = getBoundingBoxCenter(d3.select(`#${idGroup}`));

    const angle = angleX > 90 ? angleY : -angleY;

    groupAngle.angle = Number(angle / (180 / Math.PI));
    if (idx > -1) {
      arr[idx] = groupAngle;
    } else {
      arr.push(groupAngle);
    }

    savedTransformRef.current = arr;
  };

  /** calculate the translate and rotate between transfert guide */
  const constructTransformTransfert = (tracings) => {
    if (!tracings) return;
    const superGeneral = editTracingsSuperpo.find((it) => it.code === SUPER_GENERALE);
    if (!superGeneral) return;
    const refTransfertLineStartA = { x: 10000, y: 0 };
    const refTransfertLineEndA = { x: 0, y: 0 };
    const refTransfertLineStartB = { x: 0, y: 0 };
    const refTransfertLineEndB = { x: 0, y: 100 };

    let newTracings = [...tracings].sort((a, b) => Number(a.code) - Number(b.code));
    newTracings = newTracings.map((t, i) => {
      const originGeneral = superGeneral.tracings.find((it) => it.id === t.id);
      const originTransfert = originGeneral.transfertGuide;
      const pointsOriginTranfert = getPointsFromPath(originTransfert.path);
      const transfertPointOrigin = { x: pointsOriginTranfert[1].x, y: pointsOriginTranfert[1].y };
      const xDistOrigin = distancePointToLine(
        transfertPointOrigin,
        refTransfertLineStartA,
        refTransfertLineEndA
      );
      const yDistOrigin = distancePointToLine(
        transfertPointOrigin,
        refTransfertLineStartB,
        refTransfertLineEndB
      );

      /** */
      const newT = { ...t };
      const { transfertGuide } = t;
      const transfert = d3.select(`#${transfertGuide.id}`);
      const dImplanLine = {
        imgpath: transfert.attr('d'),
        color: 'black',
        id: transfertGuide.id
      };
      const points = getControlPointFromTracing(dImplanLine);
      const transfertPoint = { x: points[1].cx, y: points[1].cy };
      //get transfert guide referentiel by refstep code (debut, eval, content, fin)
      const xDist = distancePointToLine(
        transfertPoint,
        refTransfertLineStartA,
        refTransfertLineEndA
      );
      const yDist = distancePointToLine(
        transfertPoint,
        refTransfertLineStartB,
        refTransfertLineEndB
      );
      const dx = xDistOrigin - xDist;
      const dy = yDistOrigin - yDist;
      const distance = { x: dy, y: dx };
      const tracingGroupId = 'tracingGroup_' + t.id;
      const obj = savedTransformRef.current.find((savedGroup) => {
        return savedGroup.idGroup === tracingGroupId;
      });
      const angle = obj ? -obj.angle : null;
      const centerPoint = obj ? obj.centerPoint : {};
      newT.centerPoint = centerPoint;
      newT.angle = angle;

      newT.distance = distance;
      return { ...newT };
    });
    return newTracings;
  };
  //--------------------------------------------------------------------
  /* 
  validate xml
  */
  const handleValidate = async () => {
    if (disabledField) return;
    try {
      if (!svgRef || !svgRef.current) return;
      if (!editSuperposition) return;
      if (!implanLine) return;
      if (!implanLine.path) {
        throw new Error('Veuillez placer la ligne implantaire');
      }
      const svg = d3.select(svgRef.current);
      svg
        .transition()
        .duration(TRANSITION_TIME)
        .selectAll('g')
        .attr('transform', 'translate(0,0) scale(1)');
      /** remove all circle or */
      svg.selectAll('circle').remove();
      svg.selectAll('use').remove();
      timer.current = setTimeout(async () => {
        try {
          const xmlData = xmlToBase64(toSvgData(document.getElementById('svg')));
          const pathImplanGroup = d3.select(`#${IMPLAN_LINE}`);
          let newImplanLine = null;
          let newTracings = null;
          if (pathImplanGroup && !pathImplanGroup.empty()) {
            newImplanLine = { ...implanLine, lockChange: true, updatedAt: null };
            const newPath = d3.select(`#${IMPLAN_LINE_IDPATH}`).attr('d');
            newImplanLine.path = newPath;
            /** duplicate this path in all trancings point */
            newTracings = [...currentTracings].map((t) => {
              const newT = { ...t };
              const implanLineTracing = newImplanLine
                ? { ...newImplanLine, id: `${IMPLAN_LINE_IDPATH}_${t.id}` }
                : null;
              return {
                ...newT,
                implanLine: implanLineTracing
              };
            });
          }
          let savedEditTracing = [...newTracings];
          setTimeout(() => {
            newTracings = newTracings.map((resetT) => {
              const { tracings, distance } = resetT;
              const tracingGroupId = 'tracingGroup_' + resetT.id;
              const obj = savedTransformRef.current.find((savedGroup) => {
                return savedGroup.idGroup === tracingGroupId;
              });
              const angle = obj ? -obj.angle : 0;
              const centerPoint = obj ? obj.centerPoint : null;
              const newReset = tracings.map((tt) => {
                let updP = getTransformPath(tt.imgpath, null, angle, centerPoint);
                return {
                  ...tt,
                  imgpath: updP
                };
              });
              return { ...resetT, tracings: newReset };
            });
            setcurrentTracings(newTracings);
            setTimeout(() => {
              newTracings = constructTransformTransfert(newTracings);
              newTracings = newTracings.map((resetT) => {
                const foundedIdx = savedEditTracing.findIndex((f) => f.id === resetT.id);
                const founded = savedEditTracing[foundedIdx];
                const { tracings, distance } = resetT;

                const tracingGroupId = 'tracingGroup_' + resetT.id;
                const obj = savedTransformRef.current.find((savedGroup) => {
                  return savedGroup.idGroup === tracingGroupId;
                });

                const angle = obj ? -obj.angle : null;
                const centerPoint = obj ? obj.centerPoint : {};
                founded.distance = distance;
                founded.angle = angle;
                founded.centerPoint = centerPoint;
                savedEditTracing[foundedIdx] = founded;

                const newReset = tracings.map((tt) => {
                  let updP = applyTranformToGroupToPath(tt.imgpath, null, {
                    x: distance.x,
                    y: distance.y
                  });
                  return {
                    ...tt,
                    imgpath: updP
                  };
                });
                return { ...resetT, tracings: newReset };
              });
              setcurrentTracings(savedEditTracing);

              const newEditSuper = {
                ...editSuperposition,
                xmlData,
                tracings: [...savedEditTracing],
                implanLine: newImplanLine
              };

              onComplete(newEditSuper);
              handleClose();
            }, 200);
          }, 500);
        } catch (error) {
          console.error(error);
          notif(null, error);
        }
      }, Number(TRANSITION_TIME + 100));
    } catch (error) {
      console.error(error);
      notif(null, error);
    }
  };
  // ----------------------------------------------------------------------
  const disabledSave =
    disabledField || (editSuperposition.implanLine && editSuperposition.implanLine.path)
      ? true
      : false;
  useEffect(() => {
    if (!svgRef || !svgRef.current) return;
    const svg = d3.select(svgRef.current);
    /* zoom handle */
    svg.call(
      zoomHandler
        .extent([
          [0, 0],
          [SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT]
        ])
        .scaleExtent([1, 8])
        .on('zoom', zoomed)
    );
  }, [svgRef.current]);
  /* zoom function */
  const zoomed = ({ transform }) => {
    if (!svgRef || !svgRef.current) return;
    const g = d3.select('#globalGroup');
    g.attr('transform', transform);
  };
  useEffect(() => {
    if (!timer || !timer.current) return;
    // clear on component unmount
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  //--------------------------------------------------------------------

  const onSelectImplanLine = async () => {
    if (disabledField) return;
    if (!currentTracings) return;
    if (!implanLine) return;
    if (implanLine.path) return;
    /** apply tranform to all path within group */
    const newTracings = currentTracings.map((trac) => {
      const { tracings } = trac;
      const newTrac = tracings.map((it) => {
        //const idPath = `id_${trac.id}_${it.id}`;
        const idPath = getSpecificId(it.id) ? it.id : `id_${trac.id}_${it.id}`;
        let contentPath = d3.select(`#${idPath}`).attr('d');
        let commands = [];
        if (contentPath) {
          contentPath = toRel(contentPath);
          commands = parseToCommands(contentPath);
        }
        return { ...it, imgpath: contentPath, commands: commands };
      });
      return { ...trac, tracings: newTrac };
    });
    setcurrentTracings(newTracings);
    const { start, end, width } = IMPLANLINE_MAXI_DIMENSIONS;
    const linePath =
      editSuperposition.code === 2 ? drawLineMaxi(start, end, width) : 'M895 652 250 500';
    setimplanLine({ path: linePath, updatedAt: new Date() });
  };
  const getSpecificId = (id) => {
    try {
      const arr = String(id).split('_');
      for (let i = 0; i < arr.length; i++) {
        const str = arr[i];
        if (specificsId.includes(String(str))) {
          return true;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  };
  //--------------------------------------------------------------------
  if (!editSuperposition) return <></>;
  //--------------------------------------------------------------------
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          ml: `${SVGEDITOR_TOOLBAR_WIDTH}px`,
          zIndex: 1300
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {`Edition de ${editSuperposition.label}`}
          </Typography>
          <SvgEditorCloseBtn onClose={handleClose} />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: SVGEDITOR_TOOLBAR_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: SVGEDITOR_TOOLBAR_WIDTH,
            boxSizing: 'border-box'
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <DrawerHeader />
        <Divider />
        <List sx={{ mt: 2 }}>
          <ListItem disablePadding>
            <Button
              fullWidth
              disabled={disabledField}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                mx: 2.5
              }}
              onClick={() => handleValidate()}
              variant="contained"
              color="primary"
            >
              <DoneIcon /> Valider les points
            </Button>
          </ListItem>
        </List>
        <Divider />
        <List sx={{ mt: 4 }}>
          {editSuperposition.code !== SUPER_GENERALE && implanLine && (
            <AddImplanLine
              implanLine={implanLine}
              editSuperposition={editSuperposition}
              onSelectImplanLine={onSelectImplanLine}
            />
          )}
        </List>
      </Drawer>
      <TransformSuper
        svgRef={svgRef}
        editTracingGroupRef={editTracingGroupRef}
        boxTransformGroupRef={boxTransformGroupRef}
        hancleCloseSuperTransform={hancleCloseSuperTransform}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          px: 3,
          width: `100%`,
          height: `calc(100vh - (${toolbar?.minHeight}px + ${15}px))`,
          marginTop: `${toolbar?.minHeight + 10}px`,
          overflow: 'hidden'
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id="svg"
          ref={svgRef}
          viewBox={`0 0 ${SVGEDITOR_WIDTH} ${SVGEDITOR_HEIGHT}`}
          style={{
            backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=')`,
            height: `100%`,
            width: `100%`
          }}
          preserveAspectRatio="xMinYMin"
        >
          <defs>
            <g id="pointer" transform="scale(2.8)">
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                focusable="false"
                aria-hidden="true"
                data-testid="ControlCameraIcon"
                tabIndex="-1"
                title="ControlCamera"
              >
                <path d="M15.54 5.54 13.77 7.3 12 5.54 10.23 7.3 8.46 5.54 12 2zm2.92 10-1.76-1.77L18.46 12l-1.76-1.77 1.76-1.77L22 12zm-10 2.92 1.77-1.76L12 18.46l1.77-1.76 1.77 1.76L12 22zm-2.92-10 1.76 1.77L5.54 12l1.76 1.77-1.76 1.77L2 12z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
            </g>
          </defs>
          <g id="globalGroup" cursor="grab">
            <g id={TRANCING_GROUP_ID} />
          </g>
        </svg>
        <SvgToImgDownload svgEl="svg" ref={svgRef} addRight="80" />
      </Box>
    </Box>
  );
}
// ----------------------------------------------------------------------

const AddImplanLine = ({ implanLine, editSuperposition, onSelectImplanLine }) => {
  if (!editSuperposition) return <></>;
  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      {editSuperposition.implanLine ? (
        <>
          <ListItemButton
            sx={{
              justifyContent: 'initial',
              px: 2.5
            }}
            disabled
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 3,
                justifyContent: 'center'
              }}
            >
              <DoneIcon />
            </ListItemIcon>
            <ListItemText primary={`Placement de la ligne implantaire effectué`} />
          </ListItemButton>
        </>
      ) : (
        <ListItemButton
          sx={{
            justifyContent: 'initial',
            px: 2.5
          }}
          onClick={() => onSelectImplanLine()}
          disabled={implanLine.lookChange}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 3,
              justifyContent: 'center'
            }}
          >
            <PolylineIcon />
          </ListItemIcon>
          <ListItemText primary="Placement de la ligne implantaire" />
        </ListItemButton>
      )}
    </ListItem>
  );
};
