import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import settingsReducer from './slices/settings';
import authReducer from './slices/authSlice';
import refReducer from './slices/refSlice';
import patientReducer from './slices/patientSlice';
import editSvgReducer from './slices/svgSlice';
import manageUserReducer from './slices/manageUserSlice';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-ocs-',
  whitelist: ['settings', 'editSvg']
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  auth: authReducer,
  ref: refReducer,
  patient: patientReducer,
  editSvg: editSvgReducer,
  manageUser: manageUserReducer
});

export { rootPersistConfig, rootReducer };
