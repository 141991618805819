import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import PolylineIcon from '@mui/icons-material/Polyline';
import { styled, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import DoneIcon from '@mui/icons-material/Done';
import NearMeIcon from '@mui/icons-material/NearMe';
import { MIconButton } from 'theme/@material-extend/Buttons';
import {
  SVGPathData,
  SVGPathDataTransformer,
  SVGPathDataEncoder,
  SVGPathDataParser
} from 'svg-pathdata';
import * as d3 from 'd3';
import { zoom } from 'd3-zoom';
import { drag } from 'd3-drag';
import { path } from 'd3-path';
import {
  updatePath,
  setMovePoint,
  getBoundingBoxCenter,
  getMovePoint,
  drawLineMaxi,
  parseToCommands,
  toRel,
  applyTranformToGroupToPath,
  getControlPointFromTracing,
  getPerpendLineVerticalPath,
  getPointsFromPath
} from 'utils/pathUtil';
import {
  SVGEDITOR_WIDTH,
  SVGEDITOR_HEIGHT,
  SVGEDITOR_POINT_RADIUS,
  SVGEDITOR_LABEL_SPACING,
  SVGEDITOR_TOOLBAR_WIDTH,
  API_URL,
  SUPER_GENERALE,
  SUPER_MAXI,
  SUPER_MANDI,
  SUPER_COLOR__FILL,
  IMPLAN_LINE,
  IMPLAN_LINE_IDPATH,
  TRANCING_GROUP_ID,
  SVGEDITOR_TRACING_STROKEWIDTH,
  IMPLANLINE_MAXI_DIMENSIONS,
  TRACING_TYPE_MAXI,
  TRACING_TYPE_MANDI,
  TRANSFERT_GUIDE_IDPATH,
  IMPLAN_LINE_MAXI,
  IMPLAN_LINE_MANDI
} from 'config/appConfig';

import useReferentiel from 'hooks/useReferentiel';
import { blobToBase64 } from 'utils/imageUtil';
import usePointTracing from 'hooks/usePointTracing';
import useNotification from 'hooks/useNotification';
import {
  distanceBetweenPoints,
  toSvgData,
  xmlToBase64,
  getTransform,
  setTransform,
  ROTATE,
  TRANSLATE,
  SCALE,
  distance,
  calculateAngles,
  distancePointToLine
} from 'utils/d3Util';
import usePatient from 'hooks/usePatient';
import SvgEditorCloseBtn from 'components/d3/SvgEditorCloseBtn';
import useEditPath from 'hooks/useEditPath';
import SvgToImgDownload from 'components/media/SvgToImgDownload';

import TransformSuper from './TransformSuper';
// ----------------------------------------------------------------------
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));
const TRANSITION_TIME = 750;
const TRACING_GROUP = {
  globalGroup: TRANCING_GROUP_ID,
  idGroup: null,
  currentNoTransform: null
};
// ----------------------------------------------------------------------
const specificsId = [IMPLAN_LINE_IDPATH, TRANSFERT_GUIDE_IDPATH];
export default function DialogSuperCombineLocalEditor({
  editSuperposition,
  superposition,
  editTracings,
  onComplete,
  handleClose
}) {
  const {
    mixins: { toolbar }
  } = useTheme();
  const { refs } = useReferentiel();
  const { notif } = useNotification();
  /* svg editor */
  const svgRef = useRef(null);
  const zoomHandler = zoom();
  const handler = drag();
  const timer = useRef(null);
  const { patient } = usePatient();
  const disabledField = patient && patient.id && patient.lockOff;
  const [currentTracings, setcurrentTracings] = useState(null);
  const [disabledSave, setdisabledSave] = useState(true);
  //--------------------------------------------------------------------
  const boxTransformGroupRef = useRef(null);
  const editTracingGroupRef = useRef(TRACING_GROUP);
  // ----------------------------------------------------------------------
  const {
    initEditSvgGroup,
    handleUpdateSvgGroup,
    getEditGroupRef,
    restoreToStep,
    redrawUsesForGroup
  } = useEditPath();
  // ----------------------------------------------------------------------
  /**listener keydown for edit path */
  useEffect(() => {
    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, []);

  // ----------------------------------------------------------------------
  const KEYDOWN = [90, 89];
  const keydownHandler = (e) => {
    if (KEYDOWN.includes(e.keyCode) && e.ctrlKey) {
      const { histoGroup, createdAt } = getEditGroupRef();
      const isCtrlZ = e.keyCode === 90;
      const isHistoNotEmpty = histoGroup.length > 0;
      if (!isHistoNotEmpty) return;
      const idxCurrentState = histoGroup.findIndex((it) => {
        return it.createdAt.getTime() === createdAt.getTime();
      });
      const idx = isCtrlZ ? idxCurrentState - 1 : idxCurrentState + 1;
      if (idx < 0 || idx > histoGroup.length - 1) return;

      const goToHisto = histoGroup[idx];
      restoreToStep(goToHisto);
    }
  };
  //--------------------------------------------------------------------
  useEffect(() => {
    if (!editSuperposition) return;
    if (!editSuperposition.tracings) return;
    setcurrentTracings([...editSuperposition.tracings]);
  }, [editSuperposition]);
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!superposition) return;
    const { enabledReport } = superposition;
    setdisabledSave(enabledReport);
  }, [superposition]);
  // ----------------------------------------------------------------------
  /** construct tracing on svg */
  useEffect(() => {
    if (!currentTracings) return;
    //reset
    boxTransformGroupRef.current.style.display = 'none';
    const gGlobal = d3.select(`#${TRANCING_GROUP_ID}`);
    const tracingsByGroup = getGroupTracings();
    tracingsByGroup.forEach((tByGroup) => {
      const { fixed, group, groupTracings } = tByGroup;
      const tracingGroupId = 'tracingGroup_' + group;
      const toBeDeleted = d3.select(`#${tracingGroupId}`);
      if (!toBeDeleted.empty()) {
        toBeDeleted.remove();
      }
      const g = gGlobal
        .append('g')
        .attr('id', tracingGroupId)
        .attr('idGroup', tracingGroupId)
        .attr('fixed', fixed ? true : null);

      groupTracings.forEach((trac) => {
        const { color, tracings } = trac;

        const addiTracings = [...tracings];
        if (trac.transfertGuide) {
          addiTracings.push({ ...trac.transfertGuide, imgpath: trac.transfertGuide.path });
        }
        if (trac.implanLine) {
          addiTracings.push({
            ...trac.implanLine,
            imgpath: trac.implanLine.path,
            id: `${group}_${IMPLAN_LINE_MAXI}`,
            display: true
          });
        }
        addiTracings.forEach((it) => {
          const idPath = getSpecificId(it.id) ? it.id : `id_${trac.id}_${it.id}`;
          g.append('path')
            .attr('id', idPath)
            .attr('code', it.code)
            .attr('idPath', idPath)
            .attr('idGroup', tracingGroupId)
            .attr('d', it.imgpath)
            .attr('fill', 'transparent')
            .attr('stroke', color ? color : 'black')
            .attr('stroke-width', SVGEDITOR_TRACING_STROKEWIDTH)
            .attr('opacity', it.display ? '1' : 0);
        });

        g.on('mouseover', function (d) {
          d3.select(this).style('cursor', 'move').attr('fill', 'black');
        })
          .on('mouseout', function (d) {})
          .on('click', (e) => {
            handleClickOnPath(tracingGroupId);
          })
          .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended))
          .raise();
      });
    });

    if (editSuperposition.implanLine) {
      gGlobal.select(`#${IMPLAN_LINE}`).remove();
      const { path } = editSuperposition.implanLine;
      gGlobal
        .append('path')
        .attr('opacity', 0)
        .attr('id', IMPLAN_LINE)
        .attr('d', path)
        .attr('fill', 'transparent')
        .attr('stroke', 'black')
        .attr('stroke-width', SVGEDITOR_TRACING_STROKEWIDTH);
    }

    /** initialize state of svg */
    initEditSvgGroup(TRANCING_GROUP_ID);
  }, [currentTracings]);
  // ----------------------------------------------------------------------
  const getGroupTracings = () => {
    if (!currentTracings) return;
    const arr = [];
    currentTracings.forEach((it) => {
      const { group } = it;
      const groupFounded = arr.find((a) => String(group) === String(a.group));
      if (groupFounded) {
        groupFounded.groupTracings.push(it);
      } else {
        arr.push({ group, groupTracings: [it], fixed: it.fixed });
      }
    });
    return arr;
  };
  // ----------------------------------------------------------------------
  // ----------------------------------------------------------------------
  const handleClickOnPath = (tracingGroupId) => {
    setForAllGroup('path', [{ key: 'fill', value: 'transparent' }]);
    const g = d3.select(`#${tracingGroupId}`);
    boxTransformGroupRef.current.style.display = 'none';
    const isGroupEditing = d3.select(`#${tracingGroupId}`).attr('editing') === 'true';
    const isFixed = g.attr('fixed') === 'true';
    if (!isGroupEditing && !isFixed) {
      d3.select(`#${tracingGroupId}`)
        .attr('editing', true)
        .raise()
        .selectAll('path')
        .attr('fill', SUPER_COLOR__FILL);
      boxTransformGroupRef.current.style.display = 'block';
      editTracingGroupRef.current = {
        globalGroup: TRANCING_GROUP_ID,
        idGroup: tracingGroupId,
        currentNoTransform: {
          centerPoint: getBoundingBoxCenter(d3.select(`#${tracingGroupId}`)),
          nodes: d3
            .select(`#${tracingGroupId}`)
            .selectAll('path')
            .nodes()
            .map((it) => {
              const pathGroup = d3.select(it);
              return { id: pathGroup.attr('id'), imgpath: pathGroup.attr('d') };
            })
        }
      };
    } else {
      d3.select(`#${tracingGroupId}`).attr('editing', null);
      editTracingGroupRef.current = {
        globalGroup: TRANCING_GROUP_ID,
        idGroup: tracingGroupId,
        currentNoTransform: {
          centerPoint: getBoundingBoxCenter(d3.select(`#${tracingGroupId}`))
        }
      };
    }
  };
  const setForAllGroup = (type, values) => {
    const tracingsByGroup = getGroupTracings();
    tracingsByGroup.forEach((tByGroup) => {
      const idGroup = 'tracingGroup_' + tByGroup.group;
      const groupTra = d3.select(`#${idGroup}`);
      if (!groupTra.empty()) {
        values.forEach((item) => {
          const { key, value } = item;
          if (type === 'path') {
            groupTra.selectAll('path').attr(key, value);
          } else if (type === 'group') {
            groupTra.attr(key, value);
          }
        });
      }
    });
  };
  // ----------------------------------------------------------------------
  /** drag functions */
  const dragstarted = (event, d) => {
    const { sourceEvent } = event;
    if (!sourceEvent) return;
    const { target } = sourceEvent;
    const movePoint = d3.select(target);
    const idGroup = movePoint.attr('idGroup');
    if (!idGroup) return;
  };
  const dragged = (event, d) => {
    const { x, y, dx, dy } = event;
    const { sourceEvent } = event;
    if (!sourceEvent) return;
    const { target } = sourceEvent;
    let movePoint = d3.select(target);
    if (!movePoint) return;

    const idGroup = getGroupEditing(movePoint);
    if (!idGroup) return;
    const translateValue = { x: Number(dx), y: Number(dy) };
    d3.select(`#${idGroup}`)
      .selectAll('path')
      .nodes()
      .forEach((node) => {
        const nodePath = d3.select(node);
        const currentPath = nodePath.attr('d');
        /** imgPath, scale, rotate, centerPoint, translate */
        if (currentPath) {
          const encodePath = applyTranformToGroupToPath(currentPath, null, translateValue);
          nodePath.attr('d', encodePath);
        }
      });
  };
  const dragended = (event, d) => {
    const { sourceEvent } = event;
    if (!sourceEvent) return;
    const { target } = sourceEvent;
    const movePoint = d3.select(target);
    if (!movePoint) return;
    const idGroup = getGroupEditing(movePoint);
    if (!idGroup) return;
    editTracingGroupRef.current = {
      globalGroup: TRANCING_GROUP_ID,
      idGroup,
      currentNoTransform: {
        centerPoint: getBoundingBoxCenter(d3.select(`#${idGroup}`)),
        nodes: d3
          .select(`#${idGroup}`)
          .selectAll('path')
          .nodes()
          .map((it) => {
            const pathGroup = d3.select(it);
            return { id: pathGroup.attr('id'), imgpath: pathGroup.attr('d') };
          })
      }
    };
    timer.current = setTimeout(() => {
      const isGroupEditingAgain = d3.select(`#${idGroup}`).attr('editing') === 'true';
      if (isGroupEditingAgain) {
        handleUpdateSvgGroup(TRANCING_GROUP_ID);
      }
    }, 500);
    constructTransformTransfert(currentTracings);
  };

  const getGroupEditing = (movePoint) => {
    const idGroup = movePoint.attr('idGroup');
    const g = d3.select(`#${idGroup}`);
    const isCurrentEditing = !g.empty() && g.attr('editing') === 'true' ? true : false;
    if (isCurrentEditing) {
      return idGroup;
    }

    for (let i = 0; i < currentTracings.length; i++) {
      const it = currentTracings[i];
      const { id } = it;
      const idGroup = `tracingGroup_${id}`;
      const g = d3.select(`#${idGroup}`);
      if (g && !g.empty() && g.attr('editing') === 'true') {
        return idGroup;
      }
    }

    return null;
  };
  /** calculate the translate and rotate between transfert guide */
  const constructTransformTransfert = (tracings) => {
    if (!tracings) return;
    const superGeneral = superposition.tracings.find((it) => it.code === SUPER_GENERALE);
    if (!superGeneral) return;

    const refTransfertLineY = [0, 0, 0, 100];
    const refTransfertLineX = [0, 0, 100, 0];
    const refTransfertLineStartA = { x: 10000, y: 0 };
    const refTransfertLineEndA = { x: 0, y: 0 };
    const refTransfertLineStartB = { x: 0, y: 0 };
    const refTransfertLineEndB = { x: 0, y: 100 };

    let newTracings = [...tracings].sort((a, b) => Number(a.code) - Number(b.code));
    newTracings = newTracings.map((t, i) => {
      const originGeneral = superGeneral.tracings.find((it) => it.id === t.id);
      const originTransfert = originGeneral.transfertGuide;
      const pointsOriginTranfert = getPointsFromPath(originTransfert.path);
      const transfertPointOrigin = { x: pointsOriginTranfert[1].x, y: pointsOriginTranfert[1].y };
      const xDistOrigin = distancePointToLine(
        transfertPointOrigin,
        refTransfertLineStartA,
        refTransfertLineEndA
      );
      const yDistOrigin = distancePointToLine(
        transfertPointOrigin,
        refTransfertLineStartB,
        refTransfertLineEndB
      );

      /** */
      const newT = { ...t };
      const { transfertGuide } = t;
      const transfert = d3.select(`#${transfertGuide.id}`);
      const dImplanLine = {
        imgpath: transfert.attr('d'),
        color: 'black',
        id: transfertGuide.id
      };
      const points = getControlPointFromTracing(dImplanLine);
      const transfertLine = [points[0].cx, points[0].cy, points[1].cx, points[1].cy];
      const transfertPoint = { x: points[1].cx, y: points[1].cy };
      //get transfert guide referentiel by refstep code (debut, eval, content, fin)
      const angleX = calculateAngles(refTransfertLineX, transfertLine, false); //isSuperior =false
      const angleY = calculateAngles(refTransfertLineY, transfertLine, false); //isSuperior =false
      const xDist = distancePointToLine(
        transfertPoint,
        refTransfertLineStartA,
        refTransfertLineEndA
      );
      const yDist = distancePointToLine(
        transfertPoint,
        refTransfertLineStartB,
        refTransfertLineEndB
      );
      const dx = xDistOrigin - xDist;
      const dy = yDistOrigin - yDist;
      const distance = { x: dx, y: dy };
      const centerPoint = getBoundingBoxCenter(d3.select(`#${IMPLAN_LINE}`));
      newT.centerPoint = centerPoint;
      newT.angle = angleX > 90 ? angleY : -angleY;
      newT.distance = distance;
      return { ...newT };
    });
    return newTracings;
  };
  //--------------------------------------------------------------------
  /* 
  validate xml
  */
  const handleValidate = async () => {
    if (disabledField) return;
    try {
      if (!svgRef || !svgRef.current) return;
      if (!editSuperposition) return;
      const svg = d3.select(svgRef.current);
      /** remove all circle or use */
      svg.selectAll('circle').remove();
      svg.selectAll('use').remove();
      svg.selectAll('path').attr('fill', 'transparent');
      svg
        .transition()
        .duration(TRANSITION_TIME)
        .selectAll('g')
        .attr('transform', 'translate(0,0) scale(1)');
      /** remove all circle or */
      svg.selectAll('circle').remove();
      svg.selectAll('use').remove();
      timer.current = setTimeout(async () => {
        try {
          const xmlData = xmlToBase64(toSvgData(document.getElementById('svg')));
          let newTracings = [...currentTracings];
          const implanLine = {
            ...editSuperposition.implanLine,
            lockChange: true,
            updatedAt: null
          };
          /** construct a new implan line for all news points Tracing */
          const tracingsByGroup = getGroupTracings();
          tracingsByGroup.forEach((tByGroup) => {
            const { fixed, group, groupTracings } = tByGroup;
            groupTracings.forEach((t) => {
              const idLine = `${IMPLAN_LINE_IDPATH}_${t.id}`;
            });
          });
          //update path transfert

          newTracings = [...currentTracings].map((trac) => {
            const newT = { ...trac };
            const newTrac = trac.tracings.map((it) => {
              const idPath = getSpecificId(it.id) ? it.id : `id_${trac.id}_${it.id}`;
              let contentPath = d3.select(`#${idPath}`).attr('d');
              let commands = [];
              if (contentPath) {
                contentPath = toRel(contentPath);
                commands = parseToCommands(contentPath);
              }

              return { ...it, imgpath: contentPath, commands: commands };
            });

            /** implan Line */
            if (!trac.implanLine) {
              //create a transfert guide to a new combined Point tracing
              const idLine = `${IMPLAN_LINE_IDPATH}_${trac.id}`;
              newT.implanLine = { ...editSuperposition.implanLine, id: idLine };
            }
            return {
              ...newT,
              tracings: newTrac
            };
          });

          //recalcule transform of points with guide transfert
          newTracings = constructTransformTransfert(newTracings);

          const newEditSuper = {
            ...editSuperposition,
            xmlData,
            tracings: [...newTracings]
          };
          onComplete(newEditSuper);
          handleClose();
        } catch (error) {
          console.error(error);
          notif(null, error);
        }
      }, Number(TRANSITION_TIME + 100));
    } catch (error) {
      console.error(error);
      notif(null, error);
    }
  };
  // ----------------------------------------------------------------------
  useEffect(() => {
    if (!svgRef || !svgRef.current) return;
    const svg = d3.select(svgRef.current);
    /* zoom handle */
    svg.call(
      zoomHandler
        .extent([
          [0, 0],
          [SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT]
        ])
        .scaleExtent([1, 8])
        .on('zoom', zoomed)
    );
  }, [svgRef.current]);
  /* zoom function */
  const zoomed = ({ transform }) => {
    if (!svgRef || !svgRef.current) return;
    const g = d3.select('#globalGroup');
    g.attr('transform', transform);
  };
  useEffect(() => {
    if (!timer || !timer.current) return;
    // clear on component unmount
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  //--------------------------------------------------------------------
  const getSpecificId = (id) => {
    try {
      const arr = String(id).split('_');
      for (let i = 0; i < arr.length; i++) {
        const str = arr[i];
        if (specificsId.includes(String(str))) {
          return true;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return false;
  };
  //--------------------------------------------------------------------
  if (!editSuperposition) return <></>;
  //--------------------------------------------------------------------
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          ml: `${SVGEDITOR_TOOLBAR_WIDTH}px`,
          zIndex: 1300
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {`Edition de ${editSuperposition.label}`}
          </Typography>
          <SvgEditorCloseBtn onClose={handleClose} />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: SVGEDITOR_TOOLBAR_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: SVGEDITOR_TOOLBAR_WIDTH,
            boxSizing: 'border-box'
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <DrawerHeader />
        <Divider />
        <List sx={{ mt: 2 }}>
          <ListItem disablePadding>
            <Button
              fullWidth
              disabled={disabledField}
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                mx: 2.5
              }}
              onClick={() => handleValidate()}
              variant="contained"
              color="primary"
            >
              <DoneIcon /> Enregistrer
            </Button>
          </ListItem>
        </List>
      </Drawer>
      <TransformSuper
        svgRef={svgRef}
        editTracingGroupRef={editTracingGroupRef}
        boxTransformGroupRef={boxTransformGroupRef}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          px: 3,
          width: `100%`,
          height: `calc(100vh - (${toolbar?.minHeight}px + ${15}px))`,
          marginTop: `${toolbar?.minHeight + 10}px`,
          overflow: 'hidden'
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id="svg"
          ref={svgRef}
          viewBox={`0 0 ${SVGEDITOR_WIDTH} ${SVGEDITOR_HEIGHT}`}
          style={{
            backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=')`,
            height: `100%`,
            width: `100%`
          }}
          preserveAspectRatio="xMinYMin"
        >
          <defs>
            <g id="pointer" transform="scale(2.8)">
              <svg
                className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                focusable="false"
                aria-hidden="true"
                data-testid="ControlCameraIcon"
                tabIndex="-1"
                title="ControlCamera"
              >
                <path d="M15.54 5.54 13.77 7.3 12 5.54 10.23 7.3 8.46 5.54 12 2zm2.92 10-1.76-1.77L18.46 12l-1.76-1.77 1.76-1.77L22 12zm-10 2.92 1.77-1.76L12 18.46l1.77-1.76 1.77 1.76L12 22zm-2.92-10 1.76 1.77L5.54 12l1.76 1.77-1.76 1.77L2 12z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
            </g>
          </defs>
          <g id="globalGroup" cursor="grab">
            <g id={TRANCING_GROUP_ID} />
          </g>
        </svg>
        <SvgToImgDownload svgEl="svg" ref={svgRef} addRight="80" />
      </Box>
    </Box>
  );
}
// ----------------------------------------------------------------------
