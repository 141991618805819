import React, { useState } from 'react';

import {
  Button,
  IconButton,
  Card,
  Popover,
  CardActions,
  CardContent,
  Typography,
  TextField,
  Box,
  Tooltip
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import useManageUser from 'hooks/useManageUser';

// ----------------------------------------------------------------------
export default function ChangePassBtnUser({ editUser, ...props }) {
  const [pass, setPass] = useState('');
  const [submitting, setsubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { initManageUser, changePass } = useManageUser();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handeChangePass = async (e) => {
    try {
      setsubmitting(true);
      await changePass(editUser, pass);
      setPass('');
      setsubmitting(false);
      handleClose();
    } catch (error) {
      setsubmitting(false);
    }
  };

  if (!editUser || !editUser.id) return <></>;
  return (
    <div>
      <Tooltip title={`Changer le mot de passe d'utilisateur ${editUser.name}`}>
        <Button aria-label="details" size="small" onClick={handleClick} color="error">
          <RestartAltIcon />
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Card
          sx={{
            minWidth: 275
          }}
        >
          <CardContent>
            <Typography variant="h4" color="text.primary" gutterBottom>
              Modifier le mot de passe d'utilisateur {editUser.name}
            </Typography>
            <Box noValidate autoComplete="off">
              <div>
                <TextField
                  fullWidth
                  required
                  id="outlined-required"
                  label="Nouveau mot de passe"
                  value={pass}
                  onChange={(event) => {
                    setPass(event.target.value);
                  }}
                />
              </div>
            </Box>
          </CardContent>
          <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" disabled={submitting} onClick={(e) => handeChangePass(e)}>
              Valider
            </Button>
            <Button onClick={handleClose}>Annuler</Button>
          </CardActions>
        </Card>
      </Popover>
    </div>
  );
}
