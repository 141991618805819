import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isError: false,
  manageUsers: []
};
const manageUserSlice = createSlice({
  name: 'manageUser',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setManageUsers(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.manageUsers = action.payload;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    }
  }
});
export const { startLoading, stopLoading, setError, setManageUsers } = manageUserSlice.actions;
const manageUserReducer = manageUserSlice.reducer;

export default manageUserReducer;
