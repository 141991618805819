import React, { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import useReferentiel from 'hooks/useReferentiel';
import DoneIcon from '@mui/icons-material/Done';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';
import HubIcon from '@mui/icons-material/Hub';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import * as d3 from 'd3';
import { zoom } from 'd3-zoom';
import { drag } from 'd3-drag';
import { path } from 'd3-path';
import { uniqBy } from 'lodash';
import {
  SVGEDITOR_WIDTH,
  SVGEDITOR_HEIGHT,
  SVGEDITOR_POINT_RADIUS,
  SVGEDITOR_TOOLBAR_WIDTH,
  SVGEDITOR_TRACING_STROKEWIDTH,
  SUPER_COLOR__FILL,
  TRANCING_GROUP_ID,
  TRACING_OPACITY_HIDDEN
} from 'config/appConfig';
import 'components/svg/svg.css';
import usePatient from 'hooks/usePatient';

import useNotification from 'hooks/useNotification';
import useEditPath from 'hooks/useEditPath';
import {
  setMovePoint,
  getMovePoint,
  getControlPointFromTracing,
  updatePath,
  delPointAndUpdatePath,
  getBoundingBoxCenter,
  toRel,
  parseToCommands,
  applyTranformToGroupToPath
} from 'utils/pathUtil';
import SvgToImgDownload from 'components/media/SvgToImgDownload';
import { TRANSLATE, SCALE, getTransform, toSvgData, xmlToBase64 } from 'utils/d3Util';
import TracingRightToolbar from './TracingRightToolbar';
import DeleteTracingBtn from './DeleteTracingBtn';
import PointsDrawPath from './PointsDrawPath';
import SvgEditorCloseBtn from './SvgEditorCloseBtn';
// ----------------------------------------------------------------------
const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));
/*  width: `calc(100% - ${SVGEDITOR_TOOLBAR_WIDTH}px)`, */
const TRANSITION_TIME = 750;
const TRACING_GROUP = {
  globalGroup: TRANCING_GROUP_ID,
  idGroup: null,
  currentNoTransform: null,
  editPath: 1
};
const TracingsSvgEditor = React.forwardRef(
  (
    {
      editPoint,
      title,
      handleClose,
      img64,
      tracings,
      onComplete,
      editTracingStep,
      onDeleteTracing,
      ...props
    },
    ref
  ) => {
    const { patient } = usePatient();
    const disabledField = patient && patient.id && patient.lockOff;
    const [drawPoints, setdrawPoints] = useState({ callAt: null, modeDraw: false, group: null });
    const { notif } = useNotification();
    const svgRef = useRef(null);
    const boxTransformPathRef = useRef(null);
    const boxTransformGroupRef = useRef(null);

    const editTracingGroupRef = useRef(TRACING_GROUP);
    const zoomHandler = zoom();
    const handler = drag();
    const { refs } = useReferentiel();
    const {
      mixins: { toolbar }
    } = useTheme();
    // ----------------------------------------------------------------------
    const {
      initEditSvgGroup,
      handleUpdateSvgGroup,
      getEditGroupRef,
      restoreToStep,
      redrawUsesForGroup
    } = useEditPath();
    const timer = useRef(null);

    // ----------------------------------------------------------------------
    /**listener keydown for edit path */
    useEffect(() => {
      document.addEventListener('keydown', keydownHandler);
      return () => {
        document.removeEventListener('keydown', keydownHandler);
      };
    }, []);

    // ----------------------------------------------------------------------
    const KEYDOWN = [90, 89];
    const keydownHandler = (e) => {
      if (KEYDOWN.includes(e.keyCode) && e.ctrlKey) {
        const { histoGroup, createdAt } = getEditGroupRef();
        const isCtrlZ = e.keyCode === 90;
        const isHistoNotEmpty = histoGroup.length > 0;
        if (!isHistoNotEmpty) return;
        const idxCurrentState = histoGroup.findIndex((it) => {
          return it.createdAt.getTime() === createdAt.getTime();
        });
        const idx = isCtrlZ ? idxCurrentState - 1 : idxCurrentState + 1;
        if (idx < 0 || idx > histoGroup.length - 1) return;

        const goToHisto = histoGroup[idx];
        restoreToStep(goToHisto);
      }
    };
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!timer || !timer.current) return;
      // clear on component unmount
      return () => {
        clearTimeout(timer.current);
      };
    }, []);
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!svgRef || !svgRef.current) return;

      const svg = d3.select(svgRef.current);
      svg.select('#radioImage').attr('opacity', 1);
      svg.select('#radioImage').style('filter', 'contrast(1) brightness(1) saturate(1)');
      svg.select('#radioImage').on('mousedown.drag', null);
      /* zoom handle */
      svg.call(
        zoomHandler
          .extent([
            [0, 0],
            [SVGEDITOR_WIDTH, SVGEDITOR_HEIGHT]
          ])
          .scaleExtent([1, 8])
          .on('zoom', zoomed)
      );
    }, [svgRef.current]);
    /* zoom function */
    const zoomed = ({ transform }) => {
      if (!svgRef || !svgRef.current) return;
      const g = d3.select('#globalGroup');
      g.attr('transform', transform);
    };
    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!Array.isArray(tracings) || tracings.length === 0) return;
      const timeout = setTimeout(() => {
        const g = d3.select(`#${TRANCING_GROUP_ID}`);
        const tracingsTypes = getTracingTypes();
        tracingsTypes.forEach((tracingType) => {
          const idGroup = `group_${tracingType.code}`;
          /*  remove old tracings and add new one */
          d3.select(`#${idGroup}`).remove();
          const gTracingType = g.append('g').attr('id', idGroup);
          tracings
            .filter((it) => it.refCategoryTracing.code === tracingType.code)
            .forEach((it, i) => {
              const { id, code, label, color, imgpath, fill } = it;
              const newId = `id_${id}`;
              const d = { id: newId, idPath: newId, code, color, label, imgpath, idGroup };
              const idUse = `use_${id}`;
              gTracingType
                .append('path')
                .attr('id', newId)
                .attr('idPath', newId)
                .attr('idGroup', idGroup)
                .attr('idUse', idUse)
                .attr('code', code)
                .attr('d', imgpath)
                .attr('fill', fill ? fill : 'transparent')
                .attr('stroke', color)
                .attr('vector-effect', 'non-scaling-stroke')
                .attr('stroke-width', SVGEDITOR_TRACING_STROKEWIDTH);
              const bbox = d3.select(`#${idGroup}`).node().getBBox();
              const pointMove = getMovePoint(imgpath);
              if (pointMove) {
                const { x, y, width, height } = pointMove;
                d3.select(`#${idGroup}`)
                  .append('use')
                  .attr('id', idUse)
                  .attr('idGroup', idGroup)
                  .attr('idPath', newId)
                  .attr('color', color)
                  .attr('href', '#pointer')
                  .attr('x', x)
                  .attr('y', y)
                  .attr('fill', color)
                  .attr('stroke', color)
                  .attr('vector-effect', 'non-scaling-stroke')
                  .attr('stroke-width', '1px')
                  .attr('pointer-events', 'all')
                  .on('mouseover', (event) => {
                    const isEditing = d3.select(`#${idGroup}`).attr('editing');
                    if (isEditing) return;
                    d3.select(`#${idUse}`)
                      .attr('opacity', TRACING_OPACITY_HIDDEN)
                      .style('cursor', 'move');
                  })
                  .on('mouseout', (event) => {
                    const isEditing = d3.select(`#${idGroup}`).attr('editing');
                    if (isEditing) return;
                    d3.select(`#${idUse}`).attr('opacity', 1).style('cursor', 'grab');
                  })
                  .on('click', (_, e) => handleDisplayPathCtrl(d))
                  .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended));
              }
            });
          gTracingType
            .on('mouseover', function (d) {
              d3.select(this).style('cursor', 'pointer').attr('fill', 'black');
            })
            .on('mouseout', function (d) {})
            .on('click', (e) => {
              handleGroupEditing(e, idGroup);
            })
            .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended))
            .raise();
        });
        /** initialize state of svg */
        initEditSvgGroup(TRANCING_GROUP_ID);
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    }, [tracings]);

    // ----------------------------------------------------------------------
    useEffect(() => {
      if (!drawPoints.callAt) return;
      const { modeDraw, group } = drawPoints;
      /* disable all editing group before starting a new one */
      if (modeDraw) {
        /* hide menu actions */
        d3.select('#tracing-menu').style('display', 'block');
        d3.select(`#${TRANCING_GROUP_ID}`).attr('opacity', '0');
      } else {
        d3.select(`#${TRANCING_GROUP_ID}`).attr('opacity', '1');
      }
      if (group) {
        handleDisplayPathCtrl({ ...group }, true); //isStayEditingMode
      }
    }, [drawPoints]);

    // ----------------------------------------------------------------------
    /* click on path line */
    const handleDisplayPathCtrl = (d, isStayEditingMode) => {
      if (disabledField) return;
      const circles = getControlPointFromTracing(d);
      if (!circles) return;
      const { id, idGroup, idPath } = d;
      /* disable all editing group before starting a new one */
      disableAllGroupEditing(isStayEditingMode, idGroup, idPath);
      /* hide move icon */
      const idUse = `use_${id}`;
      d3.select(`#${idUse}`).attr('opacity', 0);
      const gGroup = d3.select(`#${idGroup}`);
      gGroup.attr('editing', true).raise();
      const g = gGroup.append('g').attr('id', 'controlGroup').raise();
      drawControlGroup(g, circles);
      const bbox = d3.select(`#${id}`).node().getBBox();
      if (bbox) {
        const { x, y } = bbox;
        const { idText } = d;
        g.append('text')
          .attr('id', idText)
          .attr('x', x)
          .attr('y', y - 10)
          .style('font-size', '14px')
          .style('fill', 'red')
          .text('Fermer')
          .on('mouseover', function (d) {
            d3.select(this).style('cursor', 'pointer');
            /*resetEditPath();*/
          })
          .on('mouseout', function (d) {})
          .on('click', (_, d) => {
            d3.select(`#${idGroup}`).attr('editing', null);
            g.remove();
            d3.select(`#${idUse}`).attr('opacity', 1);
            /* hide menu actions */
            boxTransformPathRef.current.style.display = 'none';
            d3.select('#tracing-menu').style('display', 'none');
            /** re-enable all   group */
            const types = getTracingTypes();
            for (let i = 0; i < types.length; i++) {
              const t = types[i];
              const idGroup = `group_${t.code}`;
              d3.select(`#${idGroup}`).attr('opacity', 1);
              //redisplay all path withing group
              d3.select(`#${idGroup}`)
                .selectAll('path')
                .nodes()
                .forEach((p) => {
                  const myPath = d3.select(p);
                  myPath.attr('opacity', 1);
                });
              d3.select(`#${idGroup}`)
                .selectAll('use')
                .nodes()
                .forEach((p) => {
                  const myUse = d3.select(p);
                  myUse.attr('opacity', 1);
                });
              tracings
                .filter((it) => it.refCategoryTracing.code === t.code)
                .forEach((it, i) => {
                  const { id } = it;
                  const idUse = `use_${id}`;
                  d3.select(`#${idUse}`).style('display', 'block');
                });
            }
          });
        const currentPath = d3.select(`#${id}`).attr('d');
        const centerPoint = getBoundingBoxCenter(d3.select(`#${id}`));
        const currentRef = { ...editTracingGroupRef.current };
        editTracingGroupRef.current = {
          ...currentRef,
          editPath: { idPath: id, currentPath, centerPoint }
        };
      }
      boxTransformPathRef.current.style.display = 'block';
      /* display menu actions */
      d3.select('#tracing-menu').style('display', 'block');
    };

    const bindingToCircle = (item) => {
      return item
        .append('circle')
        .attr('id', (d) => d.id)
        .attr('uid', (d) => d.uid)
        .attr('idPath', (d) => d.idPath)
        .attr('label', (d) => d.label)
        .attr('cx', (d) => d.cx)
        .attr('cy', (d) => d.cy)
        .attr('r', Number(SVGEDITOR_POINT_RADIUS) + 2)
        .attr('fill', 'transparent')
        .attr('stroke', (d) => (d.isOnPath ? d.color : 'blue'))
        .on('dblclick', (d) => {
          handleDbClickOnPath(d);
          d.stopPropagation();
        })
        .on('mouseover', function (d) {
          d3.select(this).style('cursor', 'move');
        })
        .on('mouseout', function (d) {})
        .call(handler.on('start', dragstarted).on('drag', dragged).on('end', dragended))
        .raise();
    };
    const drawControlGroup = (g, circles) => {
      g.selectAll('circle')
        .data(circles, ({ uid }) => uid)
        .join(
          (enter) => {
            return bindingToCircle(enter);
          },
          (update) => {
            return update;
          },
          (exit) => {
            return exit
              .transition()
              .duration(300)
              .style('opacity', 0)
              .on('end', function () {
                d3.select(this).remove();
              });
          }
        );
    };
    /** handleDbClick to remove point on path selected */
    const handleDbClickOnPath = (event) => {
      try {
        const { target } = event;
        const toDelCircle = d3.select(target);
        const idPath = toDelCircle.attr('idPath');
        const color = toDelCircle.attr('stroke');
        if (!idPath) return;
        const idArr = toDelCircle.attr('id').split('_');
        if (idArr.length > 2) return;
        const idCircle = idArr[1];
        const circles = delPointAndUpdatePath(idPath, Number(idCircle), color);
        const g = d3.select('#controlGroup');
        drawControlGroup(g, circles);
      } catch (error) {
        console.error(error);
      }
    };

    /* disable all group editing */
    const disableAllGroupEditing = (isStayEditingMode, groupEditing, idPath) => {
      try {
        if (disabledField) return;
        if (!tracings) return;
        const types = getTracingTypes();

        for (let i = 0; i < types.length; i++) {
          const t = types[i];

          tracings
            .filter((it) => it.refCategoryTracing.code === t.code)
            .forEach((it, i) => {
              const { id } = it;
              const idGroup = `group_${t.code}`;
              const idUse = `use_${id}`;
              const g = d3.select(`#${idGroup}`);
              if (!isStayEditingMode) {
                g.attr('editing', null);
              }
              const controlGrp = g.select('#controlGroup');
              controlGrp.remove();
              if (!groupEditing || String(idGroup) !== String(groupEditing)) {
                d3.select(`#${idUse}`).style('display', 'none');
                d3.select(`#${idGroup}`).attr('opacity', 0);
              }
              if (String(idGroup) === String(groupEditing)) {
                d3.select(`#${idGroup}`)
                  .selectAll('path')
                  .nodes()
                  .forEach((p) => {
                    const myPath = d3.select(p);
                    const attrId = myPath.attr('idPath');
                    if (attrId && attrId !== idPath) {
                      myPath.attr('opacity', TRACING_OPACITY_HIDDEN);
                    }
                  });
              }
            });
        }
      } catch (error) {
        console.error(error);
      }
    };

    // ----------------------------------------------------------------------
    /** drag functions */
    const dragstarted = (event, d) => {
      if (disabledField) return;
      const { sourceEvent } = event;
      if (!sourceEvent) return;
      const { target } = sourceEvent;
      const movePoint = d3.select(target);
      const { tagName } = movePoint.node();
      /** 3 possibilities
       * move point => d is not null
       * move path => when user click on use
       * move group tracing type => when user click on one path in a group
       */
      const idGroup = movePoint.attr('idGroup');
      if (!idGroup) return;
      const g = d3.select(`#${idGroup}`);
      const groupEditing = g.attr('groupEditing');

      const isGroupEditing = groupEditing && groupEditing === 'true' ? true : false;

      if (d) {
        /* move controls points */
        d3.select(`#${d.id}`).raise().attr('stroke', 'black');
        d3.select('#controlGroup').attr('cursor', 'grabbing');
      } else if (tagName === 'path') {
        console.log();
      } else if (tagName === 'use') {
        /* move path */
        /** when user chose one path , disable all draggable action of another and when dragended reset field isDragging for all */
        actionDrag(movePoint, 'start');
        d3.select(`#${movePoint.attr('idPath')}`)
          .attr('stroke', 'black')
          .attr('isDragging', true);
        movePoint.raise().attr('stroke', 'black');
      }
    };

    const dragged = (event, d) => {
      if (disabledField) return;
      const { dx, dy, sourceEvent } = event;
      if (!sourceEvent) return;
      const { target } = sourceEvent;
      let movePoint = d3.select(target);
      const { tagName } = movePoint.node();
      /** 3 possibilities
       * move point => d is not null
       * move path => when user click on use
       * move group tracing type => when user click on one path in a group
       */
      const idGroup = movePoint.attr('idGroup');
      const g = d3.select(`#${idGroup}`);
      const groupEditing = g && !g.empty() ? g.attr('groupEditing') : null;
      const isGroupEditing = groupEditing && groupEditing === 'true' ? true : false;

      if (d) {
        /* move controls points */
        const { id, idPath } = d;
        d3.select(`#${id}`)
          .attr('cx', (d.x = event.x))
          .attr('cy', (d.y = event.y));
        updatePath(idPath, { ...d, x: event.x, y: event.y });
      } else if (tagName === 'path') {
        const idGroupDragged = isGroupEditing ? idGroup : getGroupEditing('groupEditing');
        if (!idGroupDragged) return;
        const translateValue = { x: Number(dx), y: Number(dy) };
        d3.select(`#${idGroupDragged}`)
          .selectAll('path')
          .nodes()
          .forEach((node) => {
            const nodePath = d3.select(node);
            const currentPath = nodePath.attr('d');

            /** imgPath, scale, rotate, centerPoint, translate */
            const encodePath = applyTranformToGroupToPath(currentPath, null, translateValue);
            nodePath.attr('d', encodePath);
          });
      } else if (tagName === 'use' || tagName === 'image') {
        movePoint.attr('isDragged', true);
        /* move path */
        const { dx, dy } = event;
        if (!movePoint.attr('idPath')) {
          const pathDragended = getIdPathOnDragEnded();
          movePoint = d3.select(`#${pathDragended.id}`);
        }
        const idPath = movePoint.attr('idPath');
        if (!idPath) return;
        const targetX = Number(movePoint.attr('x')) + Number(dx);
        const targetY = Number(movePoint.attr('y')) + Number(dy);
        movePoint.attr('x', targetX).attr('y', targetY);
        setMovePoint(idPath, { x: targetX, y: targetY });
      }
    };

    const actionDrag = (movePoint, action) => {
      const dragIdPath = movePoint.attr('idPath');
      const dragIdGroup = movePoint.attr('idGroup');
      let opacity = 1;
      let displayValue = 'block';
      if (action === 'start') {
        opacity = TRACING_OPACITY_HIDDEN;
        displayValue = 'none';
        /** set timeout because the click event is called after this event
        timer.current = setTimeout(() => {
          const isLocalEditing = d3.select(`#${dragIdGroup}`).attr('editing');
          if (!isLocalEditing) {
             // d3.select(`#${dragIdGroup}`).raise();
            //d3.select(`#${dragIdPath}`).raise();
          }
        }, 200); */
      }

      /** disabled or  enable all  other group */
      const types = getTracingTypes();
      for (let i = 0; i < types.length; i++) {
        const t = types[i];
        const idGroup = `group_${t.code}`;
        d3.select(`#${idGroup}`).attr('opacity', 1);
        tracings
          .filter((it) => it.refCategoryTracing.code === t.code)
          .forEach((it, i) => {
            const { id } = it;
            const useIdPath = `id_${id}`;
            const idUse = `use_${id}`;
            const isGroupNotDraggabled =
              String(dragIdPath) === String(useIdPath) && action === 'start';

            if (!isGroupNotDraggabled || action === 'end') {
              d3.select(`#${idGroup}`).attr('opacity', opacity);
              d3.select(`#${idUse}`).style('display', displayValue);
            }
          });
      }
    };
    const dragended = (event, d) => {
      if (disabledField) return;
      const { sourceEvent } = event;
      if (!sourceEvent) return;
      const { target } = sourceEvent;
      let movePoint = d3.select(target);
      const { tagName } = movePoint.node();
      /** 3 possibilities
       * move point => d is not null
       * move path => when user click on use
       * move group tracing type => when user click on one path in a group
       */
      const idGroup = movePoint.attr('idGroup');
      const g = d3.select(`#${idGroup}`);
      const groupEditing = g && !g.empty() ? g.attr('groupEditing') : null;
      const isGroupEditing = groupEditing && groupEditing === 'true' ? true : false;
      if (d) {
        /* move controls points */
        const { id, idPath, isOnPath } = d;
        d3.select(`#${id}`).attr('stroke', isOnPath ? d.color : 'blue');
      } else if (tagName === 'path') {
        const idGroupDragged = isGroupEditing ? idGroup : getGroupEditing('groupEditing');
        if (!idGroupDragged) return;
        /** when dragged end save all group in histories */
        redrawUsesForGroup(idGroupDragged);
        //reset
        editTracingGroupRef.current = {
          globalGroup: TRANCING_GROUP_ID,
          idGroup,
          currentNoTransform: {
            centerPoint: getBoundingBoxCenter(d3.select(`#${idGroupDragged}`)),
            nodes: d3
              .select(`#${idGroup}`)
              .selectAll('path')
              .nodes()
              .map((it) => {
                const pathGroup = d3.select(it);
                return { id: pathGroup.attr('id'), imgpath: pathGroup.attr('d') };
              })
          },
          editPath: null
        };
        timer.current = setTimeout(() => {
          const isGroupEditingAgain =
            d3.select(`#${idGroupDragged}`).attr('groupEditing') === 'true';
          if (isGroupEditingAgain) {
            handleUpdateSvgGroup(TRANCING_GROUP_ID);
          }
        }, 500);
      } else if (tagName === 'use' || tagName === 'image') {
        const isDragged = movePoint.attr('isDragged') === 'true';
        /* move path */
        if (!movePoint.attr('idPath')) {
          const groupDragended = getIdPathOnDragEnded();
          movePoint = d3.select(`#${groupDragended.id}`);
        }

        if (!movePoint) return;
        let idPath = movePoint.attr('idPath');
        if (!idPath) return;

        const color = movePoint.attr('color');
        movePoint.attr('stroke', color);
        d3.select(`#${idPath}`).attr('stroke', color);
        actionDrag(movePoint, 'end');
        d3.select(`#${idPath}`).attr('isDragging', null);
        timer.current = setTimeout(() => {
          if (isDragged) {
            //only save histories when use svg is dragged
            handleUpdateSvgGroup(TRANCING_GROUP_ID);
            /** reset */
            movePoint.attr('isDragged', null);
          }
        }, 500);
      }
    };
    /** when drag started, flag attr isDragging to Path
     *
     * when user move use, => to find use , we need to find path with attr isDragging ===true and return an idUse
     */
    const getIdPathOnDragEnded = () => {
      const types = getTracingTypes();
      for (let i = 0; i < types.length; i++) {
        const t = types[i];
        const idGroup = `group_${t.code}`;
        const filteredTracings = tracings.filter((it) => it.refCategoryTracing.code === t.code);
        for (let j = 0; j < filteredTracings.length; j++) {
          const it = filteredTracings[j];
          const { id } = it;
          const idPath = `id_${id}`;
          const idUse = `use_${id}`;
          const isDragging = d3.select(`#${idPath}`).attr('isDragging');
          if (isDragging === 'true') {
            return { id: idUse, idPath, idGroup };
          }
        }
      }

      return null;
    };
    // ----------------------------------------------------------------------

    /** unit functions */
    const getGroupEditing = (field) => {
      const types = getTracingTypes();
      for (let i = 0; i < types.length; i++) {
        const t = types[i];
        const idGroup = `group_${t.code}`;
        const g = d3.select(`#${idGroup}`);
        const isEditing = g && g.attr(field) === 'true' ? true : false;
        if (isEditing) {
          return idGroup;
        }
      }

      return null;
    };
    const getTracingTypes = () => {
      if (!tracings) return [];
      const res = uniqBy(tracings, (it) => {
        return it.refCategoryTracing.code;
      })
        .map((it) => it.refCategoryTracing)
        .sort((o1, o2) => o1.code - o2.code);
      return res;
    };
    const handleGroupEditing = (event, groupIdChanged) => {
      if (!boxTransformGroupRef) return;
      if (!boxTransformGroupRef.current) return;
      const { target } = event;
      if (target && target.tagName === 'text') return;
      const isGroupEditing = d3.select(`#${groupIdChanged}`).attr('groupEditing') === 'true';
      const isExistLocalEditing = getGroupEditing('editing');
      if (isExistLocalEditing) {
        d3.select('#globalGroup').selectAll('use').style('display', 'none');
        return;
      }
      const display = isGroupEditing ? 'block' : 'none';
      /** reset all field groupEditing for group */
      d3.select(`#${TRANCING_GROUP_ID}`)
        .selectAll('g')
        .attr('groupEditing', null)
        .style('cursor', 'pointer')
        .on('mouseover', function (d) {
          d3.select(this).style('cursor', 'pointer');
        });
      d3.select('#globalGroup').selectAll('use').style('display', display);
      d3.select(`#${TRANCING_GROUP_ID}`).selectAll('path').attr('fill', 'transparent');
      boxTransformGroupRef.current.style.display = 'none';
      if (groupIdChanged && !isGroupEditing) {
        d3.select(`#${groupIdChanged}`)
          .attr('groupEditing', true)
          .on('mouseover', function (d) {
            d3.select(this).style('cursor', 'move').attr('fill', 'black');
          })
          .raise();
        d3.select(`#${groupIdChanged}`)
          .attr('groupEditing', true)
          .style('cursor', 'move')
          .selectAll('path')
          .attr('fill', SUPER_COLOR__FILL);
        editTracingGroupRef.current = {
          globalGroup: TRANCING_GROUP_ID,
          idGroup: groupIdChanged,
          currentNoTransform: {
            centerPoint: getBoundingBoxCenter(d3.select(`#${groupIdChanged}`)),
            nodes: d3
              .select(`#${groupIdChanged}`)
              .selectAll('path')
              .nodes()
              .map((it) => {
                const pathGroup = d3.select(it);
                return { id: pathGroup.attr('id'), imgpath: pathGroup.attr('d') };
              })
          },
          editPath: null
        };
        boxTransformGroupRef.current.style.display = 'block';
      } else {
        editTracingGroupRef.current = {
          globalGroup: TRANCING_GROUP_ID,
          idGroup: groupIdChanged,
          currentNoTransform: {
            centerPoint: getBoundingBoxCenter(d3.select(`#${groupIdChanged}`))
          },
          editPath: null
        };
      }
    };
    // ----------------------------------------------------------------------
    /* validate xml */
    const handleValidate = async () => {
      if (disabledField) return;
      try {
        if (!tracings) return;
        if (!svgRef || !svgRef.current) return;
        if (!Array.isArray(tracings) || tracings.length < 1) return;
        const svg = d3.select(svgRef.current);
        d3.select('#globalGroup').selectAll('path').attr('fill', 'transparent');
        d3.select('#globalGroup').selectAll('use').style('display', 'none');
        svg
          .transition()
          .duration(TRANSITION_TIME)
          .selectAll('g')
          .attr('transform', 'translate(0,0) scale(1)');

        timer.current = setTimeout(async () => {
          const newTracings = tracings.map((it) => {
            const { id } = it;
            try {
              const newId = `id_${id}`;
              let contentPath = d3.select(`#${newId}`).attr('d');
              let commands = [];
              if (contentPath) {
                contentPath = toRel(contentPath);
                commands = parseToCommands(contentPath);
              }
              return { ...it, imgpath: contentPath, commands: commands };
            } catch (error) {
              console.error(error);
              return { ...it };
            }
          });
          const xmlData = xmlToBase64(toSvgData(document.getElementById('svg')));
          onComplete(newTracings, xmlData);
          //reset
          boxTransformPathRef.current.style.display = 'none';
          boxTransformGroupRef.current.style.display = 'none';
          d3.select('#tracing-menu').style('display', 'none');
          handleClose();
        }, Number(TRANSITION_TIME + 100));
      } catch (error) {
        notif(null, error);
      }
    };
    const changeToModeDrawPoints = () => {
      const isModeDraw = drawPoints.modeDraw;
      if (!tracings) return;

      setdrawPoints({ callAt: new Date(), modeDraw: !isModeDraw });
    };

    /*  */
    const constructTracingAndClose = () => {
      constructTracing(true);
    };
    const constructTracing = (isClosed) => {
      try {
        if (!svgRef || !svgRef.current) return;

        const curve = d3.line().curve(d3.curveNatural);
        const svg = d3.select(svgRef.current);
        const g = svg.select('#groupPointsDrawPath');
        const allPath = g.selectAll('circle');
        const arrPoint = [];
        allPath.nodes().forEach((it) => {
          const x = d3.select(it).attr('cx');
          const y = d3.select(it).attr('cy');
          arrPoint.push([x, y]);
        });
        const dPath = curve(arrPoint);
        g.append('path')
          .style('stroke', 'black')
          .style('strokeWidth', 3)
          .style('strokeLinejoin', 'round')
          .style('strokeLinecap', 'round')
          .style('fill', 'none')
          .attr('d', isClosed ? dPath + ' Z' : dPath);
      } catch (error) {
        console.error(error);
      }
    };
    const validateDrawPoints = () => {
      if (!svgRef || !svgRef.current) return;
      if (!tracings) return;
      let group = null;
      for (let i = 0; i < tracings.length; i++) {
        const it = tracings[i];
        const { id } = it;

        const newId = `id_${id}`;
        const isEditing = d3.select(`#group_${id}`).attr('editing');

        if (isEditing === 'true') {
          group = { ...it, idGroup: `group_${id}`, id: newId };
          try {
            const groupPathDraw = d3.select('#groupPointsDrawPath').selectAll('path');
            if (!groupPathDraw) return;
            if (!groupPathDraw.nodes()) return;
            if (groupPathDraw.nodes().length === 0) {
              constructTracing();
            }
            const pathOnDraw = d3
              .select('#groupPointsDrawPath')
              .selectAll('path')
              .filter(function (d, i) {
                return i === 0;
              });

            if (pathOnDraw) {
              const drawContentPath = pathOnDraw.attr('d');
              if (drawContentPath) {
                d3.select(`#${newId}`).attr('d', drawContentPath);
                setdrawPoints({ callAt: new Date(), modeDraw: false, group });
              }
            }
          } catch (error) {
            console.error(error);
          }
          break;
        }
      }
    };

    // ----------------------------------------------------------------------
    const width = `calc(100% - ${SVGEDITOR_TOOLBAR_WIDTH}px)`;
    const height = `calc(100vh - (${toolbar?.minHeight}px + ${15}px))`;
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            ml: `${SVGEDITOR_TOOLBAR_WIDTH}px`,
            zIndex: 1300
          }}
        >
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" noWrap component="div">
              {title ? title : 'Modifier les tracings tracés.'}
            </Typography>
            {editPoint && editPoint.label && editPoint.refstep ? (
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ backgroundColor: editPoint.refstep.color, p: 1 }}
              >
                {`${editPoint ? editPoint.label : ''}`}
              </Typography>
            ) : (
              <></>
            )}
            <SvgEditorCloseBtn onClose={handleClose} />
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: SVGEDITOR_TOOLBAR_WIDTH,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: SVGEDITOR_TOOLBAR_WIDTH,
              boxSizing: 'border-box'
            }
          }}
          variant="permanent"
          anchor="left"
        >
          <DrawerHeader />
          <Divider />
          <List sx={{ mt: 4 }}>
            <ListItem disablePadding>
              <Button
                fullWidth
                disabled={disabledField}
                sx={{
                  minHeight: 48,
                  justifyContent: 'center',
                  mx: 2.5
                }}
                onClick={() => handleValidate()}
                variant="contained"
                color="primary"
              >
                <DoneIcon /> Valider les tracés
              </Button>
            </ListItem>
          </List>
          <Divider />
          <Box sx={{ my: 3, px: 2 }}>
            {editTracingStep && !disabledField && (
              <DeleteTracingBtn
                editTracingStep={editTracingStep}
                onDeleteTracing={onDeleteTracing}
              />
            )}
          </Box>

          {/*  
            <Divider />
          <Box sx={{ my: 3, px: 2, display: 'none' }} id="tracing-menu">
            <List sx={{ mt: 4 }}>
              {drawPoints.modeDraw ? (
                <>
                  <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5
                      }}
                      onClick={() => constructTracing()}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center'
                        }}
                      >
                        <RoundaboutRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Construire les tracés" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5
                      }}
                      onClick={() => constructTracingAndClose()}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center'
                        }}
                      >
                        <RotateRightIcon />
                      </ListItemIcon>
                      <ListItemText primary="Construire et fermer les tracés" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5
                      }}
                      onClick={() => validateDrawPoints()}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center'
                        }}
                      >
                        <DoneIcon />
                      </ListItemIcon>

                      <ListItemText primary="Valider" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5
                      }}
                      onClick={() => changeToModeDrawPoints()}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                          color: drawPoints.modeDraw ? 'red' : ''
                        }}
                      >
                        {drawPoints.modeDraw ? <CancelPresentationIcon /> : <HubIcon />}
                      </ListItemIcon>

                      <ListItemText primary={'Annuler'} />
                    </ListItemButton>
                  </ListItem>
                </>
              ) : (
                <>
                  <ListItem>
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: 'initial',
                        px: 2.5
                      }}
                      onClick={() => changeToModeDrawPoints()}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 3,
                          justifyContent: 'center',
                          color: drawPoints.modeDraw ? 'red' : ''
                        }}
                      >
                        <HubIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Créer le tracé par points'} />
                    </ListItemButton>
                  </ListItem>
                </>
              )}
            </List>
          </Box>*/}
        </Drawer>
        <TracingRightToolbar
          svgRef={svgRef}
          editTracingGroupRef={editTracingGroupRef}
          boxTransformGroupRef={boxTransformGroupRef}
          boxTransformPathRef={boxTransformPathRef}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: 'background.default',
            px: 3,
            width: `calc(100% - ${SVGEDITOR_TOOLBAR_WIDTH}px)`,
            height: `calc(100vh - (${toolbar?.minHeight}px + ${15}px))`,
            marginTop: `${toolbar?.minHeight + 10}px`,
            overflow: 'hidden'
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id="svg"
            ref={svgRef}
            viewBox={`0 0 ${SVGEDITOR_WIDTH} ${SVGEDITOR_HEIGHT}`}
            style={{
              backgroundImage: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAHUlEQVQ4jWNgYGAQIYAJglEDhoUBg9+FowbQ2gAARjwKARjtnN8AAAAASUVORK5CYII=')`,
              height: `100%`,
              width: `100%`
            }}
            preserveAspectRatio="xMinYMin"
          >
            <defs>
              <g id="pointer" transform="scale(2.8)">
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                  focusable="false"
                  aria-hidden="true"
                  data-testid="ControlCameraIcon"
                  tabIndex="-1"
                  title="ControlCamera"
                >
                  <path d="M15.54 5.54 13.77 7.3 12 5.54 10.23 7.3 8.46 5.54 12 2zm2.92 10-1.76-1.77L18.46 12l-1.76-1.77 1.76-1.77L22 12zm-10 2.92 1.77-1.76L12 18.46l1.77-1.76 1.77 1.76L12 22zm-2.92-10 1.76 1.77L5.54 12l1.76 1.77-1.76 1.77L2 12z"></path>
                  <circle cx="12" cy="12" r="3"></circle>
                </svg>
              </g>
            </defs>
            <g id="globalGroup" cursor="grab">
              <image
                id="radioImage"
                width="100%"
                height="100%"
                xlinkHref={img64}
                draggable={false}
              />
              <g id={TRANCING_GROUP_ID} />
              {/*  {drawMode && <MouseDraw thickness={4} width={width} height={height} ref={svgRef} />} */}
              {drawPoints.modeDraw && (
                <PointsDrawPath thickness={4} width={width} height={height} ref={svgRef} />
              )}
            </g>
          </svg>
          <SvgToImgDownload svgEl="svg" ref={svgRef} addRight="80" />
        </Box>
      </Box>
    );
  }
);

export default TracingsSvgEditor;
