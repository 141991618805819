import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from 'config/appConfig';
import { startLoading, stopLoading, setError, setManageUsers } from 'redux/slices/manageUserSlice';
import useNotification from 'hooks/useNotification';
// ----------------------------------------------------------------------
export default function useManageUser() {
  const { notif } = useNotification();
  const dispatch = useDispatch();
  const { manageUsers, isError, isLoading } = useSelector((state) => state.manageUser);

  return {
    isLoading,
    isError,
    manageUsers,
    initManageUser: async () => {
      try {
        const res = await axios.get(`${API_URL.manageUser}`);
        if (res && res.data) {
          dispatch(setManageUsers(res.data));
        }
        dispatch(stopLoading());
      } catch (error) {
        dispatch(setError());
        throw new Error('Users not founded');
      }
    },
    saveUser: async (user) => {
      try {
        dispatch(startLoading());
        const isEditMode = user && user.id ? true : false;
        const url = isEditMode ? `${API_URL.manageUser}/${user.id}` : API_URL.manageUser;
        if (isEditMode) {
          await axios.patch(url, { ...user });
        } else {
          await axios.post(url, { ...user });
        }
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    changePass: async (user, newPass) => {
      try {
        if (!user) return;
        if (!newPass || newPass.length < 8) {
          throw new Error('Le mot de passe devrait supérieur à 8 caractères.');
        }
        console.log('pin');
        dispatch(startLoading());
        const url = `${API_URL.manageUser}/${user.id}/changePass`;
        await axios.post(url, { newPass });
        dispatch(stopLoading());
        notif('Sauvegarde réussie');
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    delUser: async (userId) => {
      try {
        if (!userId) return;
        dispatch(startLoading());
        const url = `${API_URL.manageUser}/${userId}`;
        await axios.delete(url);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    },
    changeLock: async (userId) => {
      try {
        if (!userId) return;
        dispatch(startLoading());
        const url = `${API_URL.manageUser}/${userId}/changeLock`;
        await axios.get(url);
        dispatch(stopLoading());
      } catch (error) {
        dispatch(stopLoading());
        notif(null, error);
        throw error;
      }
    }
  };
}
